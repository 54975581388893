import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class JobsApi {
    private readonly apiController: string = 'jobs';
    constructor(private api: HttpService) { }

    getJobs(workspaceId: string, projectId: string, status: string): Observable<any[]> {
        let url = `${this.apiController}?`;
        if (workspaceId) {
            url = url + `workspaceId=${workspaceId}&`;
        }
        if (projectId) {
            url = url + `projectId=${projectId}&`;
        }
        if (status) {
            url = url + `status=${status}&`;
        }
        return this.api.get(url);
    }

    createJob(job: any): Observable<any> {
        const url = `${this.apiController}?`;
        return this.api.post(url, job);
    }

    getJob(id: string): Observable<any> {
        const url = `${this.apiController}/${id}`;
        return this.api.get(url);
    }

    getPresignedLink(key: string): Observable<any> {
        const url = `${this.apiController}/awsPreSignedUrl?resourceKey=${key}`;
        return this.api.get(url);
    }

    refereshJobStatus(id: string): Observable<any> {
        const url = `${this.apiController}/${id}/refresh`;
        return this.api.get(url);
    }
}
