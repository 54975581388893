import { Injectable } from '@angular/core';
import { RelationshipDiagram, IPropertyRelationship, IAxiom } from '../../../../@components/diagram/diagram.model';

@Injectable()
export class UtilService {

    constructor() { }

    removeSemTag(term: string): string {
        if (term) {
            if (term.indexOf('(') !== -1 && term.indexOf('(') !== 0 &&
            term.indexOf(')') !== -1 && term.indexOf(')') !== 0) {
               return term.substr(0, term.lastIndexOf('(') - 1);
           } else {
               return term;
           }
        }
    }

    getSemTag(term: string): string {
        if (term) {
            if (term.indexOf('(') !== -1 && term.indexOf('(') !== 0 &&
            term.indexOf(')') !== -1 && term.indexOf(')') !== 0) {
               return term.substr(term.lastIndexOf('('), term.length);
           } else {
               return term;
           }
        }
    }

    getConceptDefinitionsForAxiom(definition: IAxiom, keepSemTag = false): RelationshipDiagram {
        const relDiagram: RelationshipDiagram = {
            propertyGroups: [],
            inactivePropertyRelationships: [],
            isARelationships: [],
            otherRelationships: [],
            axioms: [],
        };
        if (definition.definitionStatusId) {
            relDiagram.conceptDefStatus = definition.definitionStatusId;
        }
        const sortRels = (rels: IPropertyRelationship[]) => {
            rels.sort((a, b) => {
                if (a.source.toLowerCase() > b.source.toLowerCase()) {
                    return 1;
                } else if (a.source.toLowerCase() < b.source.toLowerCase()) {
                    return -1;
                } else {
                    if (a.target.toLowerCase() > b.target.toLowerCase()) {
                        return 1;
                    } else if (a.target.toLowerCase() < b.target.toLowerCase()) {
                        return -1;
                    }
                }
            });
        };

        definition.relationships.forEach(relation => {
            // const type = getName(relation.typeId), target = getName(relation.destinationId);
            const type = relation.typeName, target = relation.destinationName;
            const rel: IPropertyRelationship = {
                active: relation.active,
                id: relation.relationshipId,
                // operations: this.definitionGuard.operations(null, null, relation),
                source: keepSemTag ? type : this.removeSemTag(type),
                sourceId: relation.typeId,
                sourceDefStatus: relation.typeDS,
                target: keepSemTag ? target : this.removeSemTag(target),
                targetId: relation.destinationId,
                targetDefStatus: relation.destinationDS,
                group: relation.group,
                // tslint:disable-next-line: max-line-length
                // charType: keepSemTag ? relation.charType.defaultTerm : this.removeSemTag(relation.charType.defaultTerm)
            };
            if (relation.active) {
                if (relation.typeId === '116680003') {
                    relDiagram.isARelationships.push(rel);
                } else {
                    if (!relDiagram.propertyGroups[relation.group]) {
                        relDiagram.propertyGroups[relation.group] = { group: [] };
                    }
                    relDiagram.propertyGroups[relation.group].group.push(rel);
                }
            } else {
                relDiagram.inactivePropertyRelationships.push(rel);
            }
        });
        sortRels(relDiagram.isARelationships);
        sortRels(relDiagram.inactivePropertyRelationships);
        relDiagram.propertyGroups.forEach(pg => {
            if (pg && pg.group) {
                sortRels(pg.group);
            }
        });
        return relDiagram;
    }

    getConceptDefinitions(definition: any, keepSemTag = false): RelationshipDiagram {
    // getConceptDefinitions(definition: Definition | IConcept, keepSemTag = false): RelationshipDiagram {
        const relDiagram: RelationshipDiagram = {
            propertyGroups: [],
            inactivePropertyRelationships: [],
            isARelationships: [],
            otherRelationships: [],
            axioms: [],
        };

        if (definition.definitionStatus) {
            relDiagram.conceptDefStatus = definition.definitionStatus;
        }
        const sortRels = (rels: IPropertyRelationship[]) => {
            rels.sort((a, b) => {
                if (a.source.toLowerCase() > b.source.toLowerCase()) {
                    return 1;
                } else if (a.source.toLowerCase() < b.source.toLowerCase()) {
                    return -1;
                } else {
                    if (a.target.toLowerCase() > b.target.toLowerCase()) {
                        return 1;
                    } else if (a.target.toLowerCase() < b.target.toLowerCase()) {
                        return -1;
                    }
                }
            });
        };

        definition.statedRelationships.forEach(relation => {
            relation.active = true;
            const rel: IPropertyRelationship = {
                active: relation.active,
                operations: [],
                source: keepSemTag ? relation.type.defaultTerm : this.removeSemTag(relation.type.defaultTerm),
                sourceId: relation.type.conceptId,
                sourceDefStatus: relation.type.definitionStatus,
                target: keepSemTag ? relation.target.defaultTerm : this.removeSemTag(relation.target.defaultTerm),
                targetId: relation.target.conceptId,
                targetDefStatus: relation.target.definitionStatus,
                group: relation.groupId,
                // tslint:disable-next-line: max-line-length
                // charType: keepSemTag ? relation.charType.defaultTerm : this.removeSemTag(relation.charType.defaultTerm),
            };
            if (relation.active) {
                if (relation.type.conceptId === '116680003') {
                    relDiagram.isARelationships.push(rel);
                } else {
                    if (!relDiagram.propertyGroups[relation.groupId]) {
                        relDiagram.propertyGroups[relation.groupId] = { group: [] };
                    }
                    relDiagram.propertyGroups[relation.groupId].group.push(rel);
                }
            } else {
                relDiagram.inactivePropertyRelationships.push(rel);
            }
        });
        sortRels(relDiagram.isARelationships);
        sortRels(relDiagram.inactivePropertyRelationships);
        relDiagram.propertyGroups.forEach(pg => {
            if (pg && pg.group) {
                sortRels(pg.group);
            }
        });
        return relDiagram;
    }

    convertTermspaceToSnowstormConceptFormat(results: any) {
        const res = {
            items: [],
        };
        results.matches.forEach(concept => {
            const obj = {
                conceptId: concept.c,
                moduleId: concept.m,
                fsn: {
                    term : concept.dt,
                },
            };
            res.items.push(obj);
        });
        return res;
    }

    guid(): string {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

}
