import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Team } from '../../../interfaces/common/teams';

@Injectable()
export class TeamsApi {
    private readonly apiController: string = 'teams';

    constructor(private api: HttpService) { }

    get teamsDataSource(): DataSource {
        return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    }

    list(): Observable<any[]> {

        return this.api.get(this.apiController)
            .pipe(map(data => data.map(item => {
                // const picture = `${this.api.apiUrl}/${this.apiController}/${item.id}/photo`;
                return { ...item };
            })));
    }

    listProjectsByTeam(teamId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/${teamId}/projects`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    listModulesByTeam(teamId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/${teamId}/modules`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    //   getCurrent(): Observable<any> {
    //     return this.api.get(`${this.apiController}/current`)
    //       .pipe(map(data => {
    //         const picture = `${this.api.apiUrl}/${this.apiController}/${data.id}/photo`;
    //         return { ...data, picture };
    //       }));
    //   }

    get(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    //   delete(id: string): Observable<boolean> {
    //     return this.api.delete(`${this.apiController}/${id}`);
    //   }

    add(item: Team): Observable<any> {
        return this.api.post(this.apiController, item);
    }

    //   updateCurrent(item: any): Observable<any> {
    //     return this.api.put(`${this.apiController}/current`, item);
    //   }

    //   update(item: any): Observable<any> {
    //     return this.api.put(`${this.apiController}/${item.id}`, item);
    //   }
}
