import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class MergeApi {
    private readonly simpleMapApiController: string = 'projects/simplemap/merge';
    private readonly refsetApiController: string = 'projects/refset/merge';
    constructor(private api: HttpService) { }

    getSimpleMapTaskReviews(projectId: string, taskListId: string, status: string): Observable<any[]> {
        let url = `${this.simpleMapApiController}/review/taskReview?`;
        if (projectId) {
            url = url + `projectId=${projectId}&`;
        }
        if (taskListId) {
            url = url + `taskId=${taskListId}&`;
        }
        if (status) {
            url = url + `status=${status}&`;
        }
        return this.api.get(url);
    }

    getRefsetWorkspaceReviews(projectId: string, workspaceId: string, status: string): Observable<any[]> {
        let url = `${this.refsetApiController}/review/workspaceReview?`;
        if (projectId) {
            url = url + `projectId=${projectId}&`;
        }
        if (workspaceId) {
            url = url + `workspaceId=${workspaceId}&`;
        }
        if (status) {
            url = url + `status=${status}&`;
        }
        return this.api.get(url);
    }

    getRefsetWorkspaceReview(workspaceReviewId: string): Observable<any> {
        const url = `${this.refsetApiController}/review/workspaceReview/${workspaceReviewId}`;
        return this.api.get(url);
    }

    createRefsetWorkspaceReviews(projectId: string, workspaceId: string): Observable<any[]> {
        const url = `${this.refsetApiController}/review/workspaceReview?`;
        const request = {
            projectId,
            workspaceId,
        };
        return this.api.post(url, request);
    }

    rebaseRefset(projectId: string, workspaceId: string, conflictResolutionStrategy: string): Observable<any[]> {
        const url = `${this.refsetApiController}/rebase/project?`;
        const wrequest = {
            projectId,
            workspaceId,
            conflictResolutionStrategy,
        };
        return this.api.post(url, wrequest);
    }

    promoteRefset(projectId: string, workspaceId: string): Observable<any[]> {
        const url = `${this.refsetApiController}/promote/project?`;
        const wrequest = {
            projectId,
            workspaceId,
        };
        return this.api.post(url, wrequest);
    }

    getProjectStatus(projectId: string): Observable<any> {
        const url = `${this.refsetApiController}/status/project/${projectId}`;
        return this.api.get(url);
    }
}
