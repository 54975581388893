import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VsacApi } from '../api/vsac.api';

@Injectable()
export class VsacService {

    constructor(private api: VsacApi) {
    }

    expandValueset(vsacTr: any): Observable<any> {
        return this.api.expandValueset(vsacTr);
    }
}
