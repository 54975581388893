import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TerminologyResourcesApi } from '../api/terminology-resources.api';
// import { UserData, User } from '../../../interfaces/common/users';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { NbAuthService } from '@nebular/auth';
// import { switchMap, map } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { TerminologyResourceData, CodesystemTerminologyResource, TermspaceTerminologyResource, IcdApiTerminologyResource, FhirTerminologyResource, VsacTerminologyResource, UmlsTerminologyResource } from '../../../interfaces/common/terminology-resources';
import { SnowstormTerminologyResource } from '../../../interfaces/common/terminology-resources';
import { Profile } from '../../../interfaces/common/profiles';

@Injectable()
export class TerminologyResourceService extends TerminologyResourceData {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();


    constructor(private api: TerminologyResourcesApi) {
        super();
    }

    // get gridDataSource(): DataSource {
    //   return this.api.usersDataSource;
    // }

    update() {
        this.resourcesUpdated.next(true);
        this.resourcesUpdated.next(false);
    }

    getTerminologyResourceInfo(id: string, type: string): Observable<any> {
        switch (type) {
            case 'SnomedTR':
                return this.getSnowstormInfo(id);
            case 'TermSpaceTR':
                return this.getTermSpaceInfo(id);
            case 'CodeSystemTR':
                return this.getCodeSystemInfo(id);
            case 'VsacTR':
                return this.getVsacInfo(id);
            case 'IcsTR':
                return this.getIcdInfo(id);
            case 'FhirTR':
                return this.getFhirInfo(id);
        }
    }


    createSnowstormTerminologyResource(str: SnowstormTerminologyResource) {
        return this.api.createSnowstormTerminologyResource(str);
    }

    updateSnowstormTerminologyResource(str: SnowstormTerminologyResource) {
        return this.api.updateSnowstormTerminologyResource(str);
    }

    deleteSnowstormTerminologyResource(str: SnowstormTerminologyResource): Observable<boolean> {
        return this.api.deleteSnowstormTerminologyResource(str);
    }

    createCodesystemTerminologyResource(cstr: CodesystemTerminologyResource) {
        return this.api.createCodesystemTerminologyResource(cstr);
    }

    updateCodesystemTerminologyResource(cstr: CodesystemTerminologyResource) {
        return this.api.updateCodesystemTerminologyResource(cstr);
    }

    deleteCodesystemTerminologyResource(str: CodesystemTerminologyResource): Observable<boolean> {
        return this.api.deleteCodesystemTerminologyResource(str);
    }

    createTermspaceTerminologyResource(tstr: TermspaceTerminologyResource) {
        return this.api.createTermspaceTerminologyResource(tstr);
    }

    updateTermspaceTerminologyResource(tstr: TermspaceTerminologyResource) {
        return this.api.updateTermspaceTerminologyResource(tstr);
    }

    deleteTermspaceTerminologyResource(tstr: TermspaceTerminologyResource): Observable<boolean> {
        return this.api.deleteTermspaceTerminologyResource(tstr);
    }

    createIcdApiTerminologyResource(icdtr: IcdApiTerminologyResource) {
        return this.api.createIcdApiTerminologyResource(icdtr);
    }

    updateIcdApiTerminologyResource(icdtr: IcdApiTerminologyResource) {
        return this.api.updateIcdApiTerminologyResource(icdtr);
    }

    deleteICDApiTerminologyResource(icdtr: IcdApiTerminologyResource): Observable<boolean> {
        return this.api.deleteICDApiTerminologyResource(icdtr);
    }

    createFhirTerminologyResource(fhirtr: FhirTerminologyResource) {
        return this.api.createFhirTerminologyResource(fhirtr);
    }

    updateFhirTerminologyResource(fhirtr: FhirTerminologyResource) {
        return this.api.updateFhirTerminologyResource(fhirtr);
    }

    deleteFhirTerminologyResource(fhirtr: FhirTerminologyResource): Observable<boolean> {
        return this.api.deleteFhirTerminologyResource(fhirtr);
    }

    createVsacTerminologyResource(vsactr: VsacTerminologyResource) {
        return this.api.createVsacTerminologyResource(vsactr);
    }

    updateVsacTerminologyResource(vsactr: VsacTerminologyResource) {
        return this.api.updateVsacTerminologyResource(vsactr);
    }

    deleteVsacTerminologyResource(vsactr: VsacTerminologyResource): Observable<boolean> {
        return this.api.deleteVsacTerminologyResource(vsactr);
    }

    createUmlsTerminologyResource(umlstr: UmlsTerminologyResource) {
        return this.api.createUmlsTerminologyResource(umlstr);
    }

    updateUmlsTerminologyResource(umlstr: UmlsTerminologyResource) {
        return this.api.updateUmlsTerminologyResource(umlstr);
    }

    deleteUmlsTerminologyResource(umlstr: UmlsTerminologyResource): Observable<boolean> {
        return this.api.deleteUmlsTerminologyResource(umlstr);
    }

    getSnowstormTerminologyResources(profile: Profile): Observable<SnowstormTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getSnowstormTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getSnowstormTerminologyResourcesByTeam(profile.id);
        }
    }

    getCodesystemTerminologyResources(profile: Profile): Observable<CodesystemTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getCodesystemTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getCodesystemTerminologyResourcesByTeam(profile.id);
        }
    }

    getTermspaceTerminologyResources(profile: Profile): Observable<TermspaceTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getTermspaceTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getTermspaceTerminologyResourcesByTeam(profile.id);
        }
    }

    getIcdApiTerminologyResources(profile: Profile): Observable<IcdApiTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getIcdApiTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getIcdApiTerminologyResourcesByTeam(profile.id);
        }
    }

    getFhirTerminologyResources(profile: Profile): Observable<FhirTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getFhirTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getFhirTerminologyResourcesByTeam(profile.id);
        }
    }

    getVsacTerminologyResources(profile: Profile): Observable<VsacTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getVsacTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getVsacTerminologyResourcesByTeam(profile.id);
        }
    }

    getUmlsTerminologyResources(profile: Profile): Observable<UmlsTerminologyResource[]> {
        if (profile.type === 'user') {
            return this.api.getUmlsTerminologyResourcesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.api.getUmlsTerminologyResourcesByTeam(profile.id);
        }
    }

    // Code System Services

    getCodeSystemCodes(id: string): Observable<any[]> {
        return this.api.getCodeSystemCodes(id);
    }

    searchCodeSystemCodes(id: string, term: string, skip: string, limit: string): Observable<any[]> {
        return this.api.searchCodeSystemCodes(id, term, skip, limit);
    }

    uploadCodes(id: string, file: any): Observable<any> {
        return this.api.uploadCodes(id, file);
    }

    importCodesFromFile(id: string, filename: any, spec: any): Observable<any> {
        return this.api.importCodesFromFile(id, filename, spec);
    }

    copyCodes(sourceCodesystemId: string, targetCodesystemId: string) {
        return this.api.copyCodes(sourceCodesystemId, targetCodesystemId);
    }

    getCodeSystemInfo(id: string): Observable<any> {
        return this.api.getCodeSystemInfo(id);
    }

    addCode(code): Observable<any> {
        return this.api.addCode(code);
    }

    getCode(id: string): Observable<any> {
        return this.api.getCode(id);
    }

    updateCode(code): Observable<any> {
        return this.api.updateCode(code);
    }

    // End of Code System Services

    getTermSpaceInfo(id: string): Observable<any> {
        return this.api.getTermSpaceInfo(id);
    }

    getSnowstormInfo(id: string): Observable<any> {
        return this.api.getSnowstormInfo(id);
    }

    getFhirInfo(id: string): Observable<any> {
        return this.api.getFhirInfo(id);
    }

    getVsacInfo(id: string): Observable<any> {
        return this.api.getVsacInfo(id);
    }

    getUmlsInfo(id: string): Observable<any> {
        return this.api.getUmlsInfo(id);
    }

    getIcdInfo(id: string): Observable<any> {
        return this.api.getIcdInfo(id);
    }

}
