import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class VsacApi {
    private readonly apiController: string = 'vsacapi';
    constructor(private api: HttpService) { }

    expandValueset(vsacTr: any): Observable<any[]> {
        const url = `${this.apiController}/${vsacTr.id}/expand`;
        return this.api.get(url);
    }

}
