import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ngx-read-more',
    styleUrls: ['./read-more.component.scss'],
    template: `
        <div class="read-more-container">
            <div [innerHTML]="currentText" [ngStyle]="{'text-align': alignText ? 'center' : ''}"></div>
            <div  [class.hidden]="hideToggle" *ngIf="!avoidToggle">
                <a class="read-more-link" (click)="toggleView($event);">Read {{isCollapsed? 'more':'less'}}</a>
            </div>
        </div>
    `,
})
export class ReadMoreComponent implements OnChanges {
    @Input() text: string;
    @Input() maxLength?: number;
    @Input() alignText = false;
    @Output() toggle = new EventEmitter();

    currentText: string;
    hideToggle = true;
    avoidToggle = false;
    public isCollapsed = true;

    constructor() { }

    ngOnChanges() {
        this.determineView();
    }

    toggleView(event) {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
        this.toggle.emit(this.isCollapsed);
        event.preventDefault();
        event.stopPropagation();
    }

    determineView() {
        if (this.text) {
            if (this.maxLength && this.text.length <= this.maxLength) {
                this.currentText = this.text;
                this.isCollapsed = false;
                this.hideToggle = true;
                this.avoidToggle = true;
                return;
            }
            this.hideToggle = false;
            if (this.isCollapsed === true) {
                this.currentText = this.text.substring(0, this.maxLength - 3) + '...';
            } else if (this.isCollapsed === false) {
                this.currentText = this.text;
            }
        }
    }
}
