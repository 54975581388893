import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FhirApi } from '../api/fhir.api';

@Injectable()
export class FhirService {

    constructor(private api: FhirApi) {
    }

    getValueset(fhirTr: any): Observable<any> {
        return this.api.getValueset(fhirTr);
    }

    expandValueset(fhirTr: any, filter: string, offset: number, count: number): Observable<any> {
        return this.api.expandValueset(fhirTr, filter, offset, count);
    }
}
