import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateFilter',
})
export class StateFilterPipe implements PipeTransform {
  transform(records: Array<any>, state: any) {
    if (records === undefined) {
      return;
    }
    const result = records.filter((item) => item.wfState ===  state);
    return result;
  }
}
