import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UmlsApi } from '../api/umls.api';

@Injectable()
export class UmlsService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: UmlsApi) {
    }

    getSabs(): Observable<any> {
        return this.api.getSabs();
    }

    search(trId: string, searchTerm: string, pageNumber?: number, pageSize?: number): Observable<any> {
        return this.api.search(trId, searchTerm, pageNumber, pageSize);
    }

    getCuiConcept(trId: string, cui: string, version: string): Observable<any> {
        return this.api.getCuiConcept(trId, cui, version);
    }

    getCuiAtoms(trId: string, uri: string): Observable<any> {
        return this.api.getCuiAtoms(trId, uri);
    }

    getSourceAtoms(trId: string, uri: string): Observable<any> {
        return this.api.getSourceAtoms(trId, uri);
    }

    getSourceParents(trId: string, uri: string): Observable<any> {
        return this.api.getSourceParents(trId, uri);
    }

    getSourceChildren(trId: string, uri: string): Observable<any> {
        return this.api.getSourceChildren(trId, uri);
    }

    getSourceAttributes(trId: string, uri: string): Observable<any> {
        return this.api.getSourceAttributes(trId, uri);
    }

}
