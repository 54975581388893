import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { RolesApi } from '../api/roles.api';
// import { UserData, User } from '../../../interfaces/common/users';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { RoleData, Role } from '../../../interfaces/common/roles';
// import { NbAuthService } from '@nebular/auth';
// import { switchMap, map } from 'rxjs/operators';

@Injectable()
export class RoleService extends RoleData {

    public rolesUpdated = new BehaviorSubject<boolean>(false);
    public rolesUpdatedEvent = this.rolesUpdated.asObservable();

    constructor(private api: RolesApi) {
        super();
    }

    // get gridDataSource(): DataSource {
    //   return this.api.usersDataSource;
    // }

    update() {
        this.rolesUpdated.next(true);
        this.rolesUpdated.next(false);
    }

    list(): Observable<Role[]> {
        return this.api.list();
    }


    get(id: string): Observable<Role> {
        return this.api.get(id);
    }

    getByTeam(orgId: string): Observable<Role[]> {
        return this.api.getByTeam(orgId);
    }

    getByUser(userId: string): Observable<Role[]> {
        return this.api.getByUser(userId);
    }

    getByUserAndTeam(userId: string, orgId: string): Observable<Role[]> {
        return this.api.getByUserAndTeam(userId, orgId);
    }

    create(role: any): Observable<Role> {
        return this.api.create(role);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(id);
    }

    updateRole(roleId: string, newRole: Role): Observable<Role> {
        return this.api.updateRole(roleId, newRole);
    }

    //   updateCurrent(user: any): Observable<User> {
    //     return this.api.updateCurrent(user);
    //   }


}
