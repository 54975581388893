import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Project } from '../../../interfaces/common/projects';

@Injectable()
export class ProjectsApi {
    private readonly apiController: string = 'projects';

    constructor(private api: HttpService) { }

    // get projectsDataSource(): DataSource {
    //     return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    // }

    list(): Observable<any[]> {
        return this.api.get(this.apiController);
    }

    listProjectsByUser(userId: string, type?: string): Observable<any[]> {
        let url = `${this.apiController}/?userId=${userId}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.api.get(url);
    }

    listProjectsByTeam(teamId: string, type?: string): Observable<any[]> {
        let url = `${this.apiController}/?teamId=${teamId}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.api.get(url);
    }

    listProjectsByWorkspace(workspaceId: string, userId: string, type?: string): Observable<any[]> {
        let url = `${this.apiController}/?workspaceId=${workspaceId}`;
        if (userId) {
            url += `&userId=${userId}`;
        }
        if (type) {
            url += `&type=${type}`;
        }
        return this.api.get(url);
    }


    get(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`);
    }

    add(item: Project): Observable<any> {
        return this.api.post(`${this.apiController}`, item);
    }

    update(id: string, item: Project): Observable<any> {
        return this.api.put(`${this.apiController}/${id}`, item);
    }

    delete(id: string): any {
        return this.api.delete(`${this.apiController}/${id}`);
    }

}
