import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class TxtImportApi {
    private readonly apiController: string = 'txtimport';
    constructor(private api: HttpService) { }

    preview(file: any): Observable<any[]> {
        const url = `${this.apiController}/preview?`;
        return this.api.post(url, file);
    }

    extractPost(file: any, columns: string[]): Observable<any[]> {
        let url = `${this.apiController}/extract?`;
        url += `columns=${columns.join(',')}`;
        return this.api.post(url, file);
    }

    extractGet(fileName: string, columns: string[]): Observable<any[]> {
        let url = `${this.apiController}/extract?`;
        url += `columns=${columns.join(',')}&filename=${fileName}`;
        return this.api.get(url);
    }

}
