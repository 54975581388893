import { Observable } from 'rxjs';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { Settings } from './settings';

export interface Team {
    name: string;
    shortName: string;
    id?: string;
    _id?: string;
    // address: Address;
}

export interface Address {
    street: string;
    city: string;
    zipCode: string;
}


export abstract class TeamData {
    // abstract get gridDataSource(): DataSource;
    // abstract getCurrentUser(): Observable<User>;
    abstract list(): Observable<Team[]>;
    // abstract get(id: string): Observable<User>;
    // abstract update(user: User): Observable<User>;
    // abstract updateCurrent(user: User): Observable<User>;
    abstract create(team: Team): Observable<Team>;
    // abstract delete(id: string): Observable<boolean>;
}
