import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { RoleService } from '../../../@core/backend/common/services/roles.service';
import { Profile } from '../../../@core/interfaces/common/profiles';
import { Workspace } from '../../../@core/interfaces/common/workspaces';
import { AppStore } from '../../../@core/stores/app.store';
import ISO6391 from 'iso-639-1';
import { UtilService } from '../../../@core/backend/common/services/util.service';
import { SnowstormService } from '../../../@core/backend/common/services/snowstorm.service';
import { TermspaceService } from '../../../@core/backend/common/services/termspace.service';
import { SctIdsService } from '../../../@core/backend/common/services/sctids.service';


@Component({
    // tslint:disable-next-line: component-selector
    selector: 'create-workspace-component',
    templateUrl: 'create-workspace.component.html',
    styleUrls: ['create-workspace.component.scss'],
})
export class CreateWorkspaceComponent implements OnInit, OnDestroy {

    private subscriptions = new Subscription();
    user: any;
    firstStep = true;
    secondStep = false;
    thirdStep = false;
    currentProfile: Profile;
    availableProfiles: Profile[] = [];
    // availableTRs = [];
    mode = null;
    newWorkspace: Workspace = {
        userId: '',
        teamId: '',
        name: '',
        type: '',
        sourceTerminologyResources: [],
        rf2ExportConfig: {
            releaseDate: '',
            moduleConceptData: {
                export: false,
                fsn: {
                    term: '',
                    descriptionId: '',
                },
                preferred: {
                    term: '',
                    descriptionId: '',
                },
                synonyms: [],
                conceptId: '',
                dependencyModuleId: '',
                dependencyModuleEffectiveTime: '',
            },
            languageConceptData: {
                export: false,
                fsn: {
                    term: '',
                    descriptionId: '',
                },
                preferred: {
                    term: '',
                    descriptionId: '',
                },
                synonyms: [],
                conceptId: '',
            },
            languageCode: '',
            termSpaceTrForIdGeneration: '',
            contributingWorkspace: false,
        },
        fhirExportConfig: {},
    };
    isoLanguages = ISO6391.getAllCodes();
    options = this.isoLanguages;
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    availableLangRefsets = [];
    availableModules = [];


    constructor(
        private dialogRef: MatDialogRef<CreateWorkspaceComponent>,
        private appStore: AppStore,
        private roleService: RoleService,
        private utilService: UtilService,
        private snowstormService: SnowstormService,
        private termspaceService: TermspaceService,
        private sctidsService: SctIdsService,
    ) { }

    ngOnInit() {
        this.loadUser();
        this.loadTeams();
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
        );
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }


    loadUser() {
        // this.availableProfiles = [];
        this.user = this.appStore.getUser();
        const userProfile: Profile = {
            name: this.user.login,
            type: 'user',
            id: this.user.id,
        };
        this.currentProfile = userProfile;
        this.availableProfiles.push(userProfile);
    }

    loadTeams() {
        // this.loadUser();
        this.subscriptions.add(
            this.roleService.getByUser(this.user.id).subscribe(roles => {
                roles.forEach(role => {
                    if (role.team && role.type === 'admin') {
                        const teamProfile: Profile = {
                            name: role.team.name,
                            type: 'team',
                            id: role.team._id,
                        };
                        let included = false;
                        this.availableProfiles.forEach(profile => {
                            if (profile.id === teamProfile.id) {
                                included = true;
                            }
                        });
                        if (included === false) {
                            this.availableProfiles.push(teamProfile);
                        }
                    }
                });
            }),

        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    compareProfiles(profile1, profile2) {
        let res = false;
        if (profile1 && profile2) {
            res = profile1.id === profile2.id;
        }
        return res;
    }

    setWorkspaceOwner(profile: Profile) {
        if (profile.type === 'team') {
            this.newWorkspace.teamId = profile.id;
            delete this.newWorkspace.userId;
        } else if (profile.type === 'user') {
            this.newWorkspace.userId = profile.id;
            delete this.newWorkspace.teamId;
        }
    }

    // loadTrsForProfile(profile: Profile) {
    //     this.setWorkspaceOwner(profile);
    //     this.availableTRs = [];
    //     this.terminologyResourcesService.getCodesystemTerminologyResources(profile).subscribe(cstr => {
    //         cstr.forEach(tr => {
    //             tr.type = 'CodeSystemTR';
    //             this.availableTRs.push(tr);
    //         });
    //     });
    //     this.terminologyResourcesService.getSnowstormTerminologyResources(profile).subscribe(str => {
    //         str.forEach(tr => {
    //             tr.type = 'SnomedTR';
    //             this.availableTRs.push(tr);
    //         });
    //     });
    //     this.terminologyResourcesService.getTermspaceTerminologyResources(profile).subscribe(ttr => {
    //         ttr.forEach(tr => {
    //             tr.type = 'TermSpaceTR';
    //             this.availableTRs.push(tr);
    //         });
    //     });
    //     this.terminologyResourcesService.getIcdApiTerminologyResources(profile).subscribe(ttr => {
    //         ttr.forEach(tr => {
    //             tr.type = 'IcdTR';
    //             this.availableTRs.push(tr);
    //         });
    //     });
    //     this.terminologyResourcesService.getFhirTerminologyResources(profile).subscribe(ttr => {
    //         ttr.forEach(tr => {
    //             tr.type = 'FhirTR';
    //             this.availableTRs.push(tr);
    //         });
    //     });
    //     this.terminologyResourcesService.getVsacTerminologyResources(profile).subscribe(vsactr => {
    //         vsactr.forEach(tr => {
    //             tr.type = 'VsacTR';
    //             this.availableTRs.push(tr);
    //         });
    //     });
    //     if (profile.name !== 'Blueprints') {
    //         this.blueprintsService.getTrBlueprints().subscribe(ttr => {
    //             ttr.forEach(tr => {
    //                 if (tr.type !== 'CodeSystemTR') {
    //                     const copiedTR = JSON.parse(JSON.stringify(tr));
    //                     copiedTR.blueprint = true;
    //                     this.availableTRs.push(copiedTR);
    //                 }
    //             });
    //         });
    //     }
    // }

    addResource() {
        this.newWorkspace.sourceTerminologyResources.push({
            type: '',
            name: '',
            reference: '',
        });
    }

    removeResource(index: number) {
        this.newWorkspace.sourceTerminologyResources.splice(index, 1);
    }

    setTerminologyResource(tr, index) {
        this.newWorkspace.sourceTerminologyResources[index].name = tr.name;
        this.newWorkspace.sourceTerminologyResources[index].reference = tr.id;
        this.newWorkspace.sourceTerminologyResources[index].type = tr.type;
    }

    createWorkspace() {
        if (this.newWorkspace.rf2ExportConfig.termSpaceTrForIdGeneration === '') {
            delete this.newWorkspace.rf2ExportConfig.termSpaceTrForIdGeneration;
        }
        this.getIds();
        this.dialogRef.close(this.newWorkspace);
    }

    async getIds() {
        if (this.newWorkspace.rf2ExportConfig.moduleConceptData.export) {
            const conceptId  = await this.sctidsService.getCommunityConceptId().toPromise();
            this.newWorkspace.rf2ExportConfig.moduleConceptData.conceptId = conceptId[0].sctId;
            const descriptionsIds = await this.sctidsService.getCommunityDescriptionId(2).toPromise();
            this.newWorkspace.rf2ExportConfig.moduleConceptData.fsn.descriptionId = descriptionsIds[0].sctId;
            this.newWorkspace.rf2ExportConfig.moduleConceptData.preferred.descriptionId = descriptionsIds[1].sctId;
        }
        if (this.newWorkspace.rf2ExportConfig.languageConceptData.export) {
            const conceptId  = await this.sctidsService.getCommunityConceptId().toPromise();
            this.newWorkspace.rf2ExportConfig.languageConceptData.conceptId = conceptId[0].sctId;
            const descriptionsIds = await this.sctidsService.getCommunityDescriptionId(2).toPromise();
            this.newWorkspace.rf2ExportConfig.languageConceptData.fsn.descriptionId = descriptionsIds[0].sctId;
            this.newWorkspace.rf2ExportConfig.languageConceptData.preferred.descriptionId = descriptionsIds[1].sctId;
        }
    }

    onNoClick(): void {
        this.dialogRef.close({});
    }

    configureExport() {
        this.firstStep = false;
        this.secondStep = true;
    }

    loadLanguages() {
        if (this.newWorkspace.sourceTerminologyResources[0].type === 'SnomedTR') {
            this.snowstormService.searchConcepts(this.newWorkspace.sourceTerminologyResources[0].reference, null,
                '< 900000000000506000 |Language type reference set (foundation metadata concept)|',
                0, 100).subscribe(langRefsetsRes => {
                    this.availableLangRefsets = langRefsetsRes['items'];
                });
        } else if (this.newWorkspace.sourceTerminologyResources[0].type === 'TermSpaceTR') {
            this.termspaceService.searchConceptsEcl(this.newWorkspace.sourceTerminologyResources[0].reference,
                // tslint:disable-next-line: max-line-length
                '< 900000000000506000 |Language type reference set (foundation metadata concept)|', null, null, null, 0, 100)
                .subscribe(langRefsetsRes => {
                    // tslint:disable-next-line: max-line-length
                    this.availableLangRefsets = this.utilService.convertTermspaceToSnowstormConceptFormat(langRefsetsRes)['items'];
                });
        }
    }

    loadModules() {
        if (this.newWorkspace.sourceTerminologyResources[0].type === 'SnomedTR') {
            this.snowstormService.searchConcepts(this.newWorkspace.sourceTerminologyResources[0].reference, null,
                '< 900000000000443000 |Module (core metadata concept)|',
                0, 100).subscribe(modules => {
                    this.availableModules = modules['items'];
                });
        } else if (this.newWorkspace.sourceTerminologyResources[0].type === 'TermSpaceTR') {
            this.termspaceService.searchConceptsEcl(this.newWorkspace.sourceTerminologyResources[0].reference,
                '< 900000000000443000 |Module (core metadata concept)|', null, null, null, 0, 100)
                .subscribe(modules => {
                    // tslint:disable-next-line: max-line-length
                    this.availableModules = this.utilService.convertTermspaceToSnowstormConceptFormat(modules)['items'];
                });
        }
    }

    loadTSProjectDetails() {
        const resourceId = this.newWorkspace.sourceTerminologyResources[0].reference;
        if (this.newWorkspace.sourceTerminologyResources[0].type === 'TermSpaceTR') {
            this.termspaceService.getProject(resourceId).subscribe(tsProject => {
                this.newWorkspace.rf2ExportConfig.releaseDate = tsProject.targetEffectiveTime;
                this.newWorkspace.rf2ExportConfig.moduleConceptData.conceptId = tsProject.editModule.conceptId;
                this.newWorkspace.rf2ExportConfig.moduleConceptData.fsn.term = tsProject.editModule.defaultTerm;
                this.newWorkspace.rf2ExportConfig.languageConceptData.conceptId = tsProject.editLangRefset.conceptId;
                this.newWorkspace.rf2ExportConfig.languageConceptData.fsn.term = tsProject.editLangRefset.defaultTerm;
                this.newWorkspace.rf2ExportConfig.languageCode = tsProject.defaultLangCode;
                this.newWorkspace.rf2ExportConfig.termSpaceTrForIdGeneration =
                    this.newWorkspace.sourceTerminologyResources[0].reference;
            });
        }
    }

    matchSynonymWithFSN(field: string) {
        this.newWorkspace.rf2ExportConfig[field].preferred.term =
            this.newWorkspace.rf2ExportConfig[field].fsn.term;
    }
}
