import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../@core/shared/shared.module';
import { UtilService } from '../../@core/backend/common/services/util.service';
import { NbCardModule, NbSpinnerModule } from '@nebular/theme';
import { FileImportDialogComponent } from './file-import-dialog.component';
import { TxtImportService } from '../../@core/backend/common/services/txtimport.service';
import { MaterialFileInputModule } from 'ngx-material-file-input';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NbCardModule,
        NbSpinnerModule,
        MaterialFileInputModule,
    ],
    providers: [
        UtilService,
        TxtImportService,
    ],
    declarations: [
        FileImportDialogComponent,
    ],
    exports: [
        FileImportDialogComponent,
    ],
    entryComponents: [
        FileImportDialogComponent,
    ],
})
export class FileImportModule { }
