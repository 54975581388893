import { NgModule } from '@angular/core';
import {
    MatIconModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCardModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatSliderModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSlideToggleModule,
} from '@angular/material';

const materialModules = [
    MatIconModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCardModule,
    MatCheckboxModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatSliderModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSlideToggleModule,
];

@NgModule({
    imports: [
        ...materialModules,
    ],
    exports: [
        ...materialModules,
    ],
})

export class CustomMaterialModule { }
