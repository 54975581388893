import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class AuthApi {
    private readonly apiController: string = 'auth';
    constructor(private api: HttpService) { }

    loginGoogleUser(googleUser: any): Observable<any> {
        const url = `${this.apiController}/loginGoogleUser?`;
        return this.api.post(url, googleUser);
    }
}
