import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { ProjectRole } from '../../../interfaces/common/project-roles';

@Injectable()
export class ProjectRolesApi {
    private readonly apiController: string = 'projectRoles';

    constructor(private api: HttpService) { }
    list(): Observable<any[]> {

        return this.api.get(this.apiController)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    get(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    getByProject(projectId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/search?projectId=${projectId}`)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    getByUser(userId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/search?userId=${userId}`)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    getByUserAndProject(userId: string, projectId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/search?projectId=${projectId}&userId=${userId}`)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    create(role: ProjectRole): Observable<any> {
        return this.api.post(`${this.apiController}`, role);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    updateRole(roleId: string, newRole: ProjectRole): Observable<ProjectRole> {
        return this.api.put(`${this.apiController}/${roleId}`, newRole);
    }

}
