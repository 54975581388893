import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlueprintsApi } from '../api/blueprints.api';

@Injectable()
export class BlueprintsService {

    constructor(private api: BlueprintsApi) {
    }

    getTrBlueprints(): Observable<any[]> {
        return this.api.getTrBlueprints();
    }

    getWfBlueprints(): Observable<any[]> {
        return this.api.getWfBlueprints();
    }

    getPatterns(): Observable<any[]> {
        return this.api.getPatterns();
    }
}
