import {
    Component,
    Input, TemplateRef, ChangeDetectionStrategy, OnChanges, SimpleChanges, Output, EventEmitter,
} from '@angular/core';
import { WorkflowOptions, WorkflowTask } from '../../../@core/interfaces/common/workflow-definition';

@Component({
    selector: 'ngx-task-group',
    templateUrl: './task-group.component.html',
    styleUrls: ['./task-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskGroupComponent implements OnChanges {
    @Input() items: {};
    @Input() taskDisplay: TemplateRef<any>;
    @Input() title: string;
    @Input() options: WorkflowOptions;
    @Output() updatedTasks = new EventEmitter<WorkflowTask<string>[]>();
    itemsK: string[];
    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items && changes.items.currentValue) {
            this.items = changes.items.currentValue;
            this.itemsK = Object.keys(this.items);
        }
    }

    updateTasks(tasks: WorkflowTask<string>[]) {
        this.updatedTasks.emit(tasks);
    }
}
