import { Observable } from 'rxjs';
import { User } from './users';

export interface ProjectRole {
    id: string;
    type: string;
    user: User;
    project: any;
  }


export abstract class ProjectRoleData {
    abstract list(): Observable<ProjectRole[]>;
    abstract getByProject(projectId: string): Observable<ProjectRole[]>;
    abstract getByUser(userId: string): Observable<ProjectRole[]>;
    abstract create(role: ProjectRole): Observable<ProjectRole>;
    abstract getByUserAndProject(userId: string, projectId: string): Observable<ProjectRole[]>;
    abstract delete(id: string): Observable<boolean>;
    abstract updateRole(id: string, newRole: ProjectRole): Observable<ProjectRole>;
}
