import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { FlexLayoutModule } from '@angular/flex-layout';
// import { ScrollingModule } from '@angular/cdk/scrolling';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CustomMaterialModule } from './material/custom-material.module';
import { ReadMoreComponent } from './read-more/read-more.component';

@NgModule({
    declarations: [
        ReadMoreComponent,
    ],
    imports: [
        CommonModule,
        // PerfectScrollbarModule,
        // FlexLayoutModule,
        // ScrollingModule
        CustomMaterialModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        CommonModule,
        // PerfectScrollbarModule,
        // FlexLayoutModule,
        // ScrollingModule,
        CustomMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        ReadMoreComponent,
    ],
})

export class SharedModule { }
