import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
// import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class SnowstormApi {
    private readonly apiController: string = 'snowstorm';
    constructor(private api: HttpService) { }

    searchConcepts(trId: string, term: string, ecl: string,
        offset: number, limit: number, searchAfter?: string): Observable<any> {
        let url = `${this.apiController}/concepts?snomedTRId=${trId}`;
        if (term) {
            url = url + `&term=${term}`;
        }
        if (ecl) {
            url = url + `&ecl=${ecl}`;
        }
        if (offset || offset === 0) {
            url = url + `&offset=${offset}`;
        }
        if (limit) {
            url = url + `&limit=${limit}`;
        }
        if (searchAfter) {
            url = url + `&searchAfter=${searchAfter}`;
        }
        return this.api.get(url);
    }

    getConcept(trId: string, conceptId: string): Observable<any> {
        const url = `${this.apiController}/concepts/${conceptId}?snomedTRId=${trId}`;
        return this.api.get(url);
    }

    getChildren(trId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/concepts/${conceptId}/children?snomedTRId=${trId}`;
        return this.api.get(url);
    }

    getAncestors(trId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/concepts/${conceptId}/ancestors?snomedTRId=${trId}`;
        return this.api.get(url);
    }

    getParents(trId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/concepts/${conceptId}/parents?snomedTRId=${trId}`;
        return this.api.get(url);
    }

    getBranches(trId: string): Observable<any[]> {
        const url = `${this.apiController}/branches?snomedTRId=${trId}`;
        return this.api.get(url);
    }

    getMembers(trId: string, refsetId: string,
        offset: number, limit: number): Observable<any> {
        let url = `${this.apiController}/members?snomedTRId=${trId}`;
        if (refsetId) {
            url = url + `&refsetId=${refsetId}`;
        }
        if (offset || offset === 0) {
            url = url + `&offset=${offset}`;
        }
        if (limit) {
            url = url + `&limit=${limit}`;
        }
        return this.api.get(url);
    }

    // tslint:disable-next-line: max-line-length
    getDomainAttributes(trId: string, parentIds: string[], proximalPrimitiveModeling?: boolean, contentType?: string): Observable<any[]> {
        let url = `${this.apiController}/domain-attributes?snomedTRId=${trId}`;
        let parentsStr = '';
        if (typeof parentIds === 'string') {
            parentsStr = parentIds;
        } else {
            parentsStr = parentIds.join(',');
        }
        url = url + `&parentIds=${parentsStr}`;
        if (typeof proximalPrimitiveModeling === 'undefined') {
            proximalPrimitiveModeling = true;
        }
        if (!contentType) {
            contentType = 'NEW_PRECOORDINATED';
        }
        url = url + `&proximalPrimitiveModeling=${proximalPrimitiveModeling}`;
        url = url + `&contentType=${contentType}`;
        return this.api.get(url);
    }

    getAttributeValues(trId: string, attributeId: string, searchTerm: string, contentType?: string) {
        let url = `${this.apiController}/attribute-values?snomedTRId=${trId}`;
        if (!contentType) {
            contentType = 'NEW_PRECOORDINATED';
        }
        url = url + `&contentType=${contentType}`;
        url = url + `&attributeId=${attributeId}`;
        if (searchTerm) {
            url += `&searchTerm=${searchTerm}`;
        }
        return this.api.get(url);
    }

}
