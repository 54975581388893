import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamsApi } from '../api/teams.api';
// import { UserData, User } from '../../../interfaces/common/users';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { NbAuthService } from '@nebular/auth';
// import { switchMap, map } from 'rxjs/operators';
// import { Project } from '../../../interfaces/common/projects';
// import { RoleService } from './roles.service';
import { TeamData, Team } from '../../../interfaces/common/teams';

@Injectable()
export class TeamService extends TeamData {

    constructor(
        private api: TeamsApi,
        // private roleService: RoleService,
        ) {
        super();
    }

    // get gridDataSource(): DataSource {
    //   return this.api.usersDataSource;
    // }

    list(): Observable<Team[]> {
        return this.api.list();
    }

    // listProjectsByTeam(teamId: string): Observable<Project[]>{
    //   return this.api.listProjectsByTeam(teamId);
    // }

    // listModulesByTeam(teamId: string): Observable<any[]>{
    //   return this.api.listModulesByTeam(teamId);
    // }

    //   getCurrentUser(): Observable<User> {
    //     return this.authService.isAuthenticated()
    //       .pipe(
    //         switchMap(authenticated => {
    //           return authenticated ? this.api.getCurrent() : of(null);
    //         }),
    //         map(u => {
    //           if (u && !u.setting) {
    //             u.setting = {};
    //           }
    //         return u;
    //       }));
    //   }


    get(id: string): Observable<Team> {
        return this.api.get(id);
    }

    create(team: Team): Observable<Team> {
        return this.api.add(team);
    }

    //   update(user: any): Observable<User> {
    //     return this.api.update(user);
    //   }

    //   updateCurrent(user: any): Observable<User> {
    //     return this.api.updateCurrent(user);
    //   }

    //   delete(id: string): Observable<boolean> {
    //     return this.api.delete(id);
    //   }
}
