import { Observable } from 'rxjs';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { Settings } from './settings';

export interface Profile {
    name: string;
    type: string;
    id: string;
    workspaceDetails?: any;
}

export abstract class ProfileData {
    // abstract get gridDataSource(): DataSource;
    // abstract getCurrentUser(): Observable<User>;
    // abstract list(): Observable<Team[]>;
    // abstract get(id: string): Observable<User>;
    // abstract update(user: User): Observable<User>;
    // abstract updateCurrent(user: User): Observable<User>;
    // abstract create(user: User): Observable<User>;
    // abstract delete(id: string): Observable<boolean>;
    abstract getDetails(profile: Profile): Observable<boolean>;
}
