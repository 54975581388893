import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { SctId } from '../../../interfaces/common/sctids';

@Injectable()
export class SctIdsApi {
    private readonly apiController: string = 'sctids';
    constructor(private api: HttpService) { }

    get(namespace: string, partition: string): Observable<SctId> {
        return this.api.get(this.apiController + '?namespace=' + namespace + '&partition=' + partition);
    }

    getCommunityConceptId(quantity?: number): Observable<SctId[]> {
        const q = quantity ? quantity : 1;
        return this.api.get(this.apiController + '/community/conceptId?quantity=' + q);
    }

    getCommunityRelationshipId(quantity?: number): Observable<SctId[]> {
        const q = quantity ? quantity : 1;
        return this.api.get(this.apiController + '/community/relationshipId?quantity=' + q);
    }

    getCommunityDescriptionId(quantity?: number): Observable<SctId[]> {
        const q = quantity ? quantity : 1;
        return this.api.get(this.apiController + '/community/descriptionId?quantity=' + q);
    }

}
