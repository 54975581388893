import {
    Component,
    OnInit,
    OnDestroy,
    Inject,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentFactory,
    ComponentRef,
    EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe.decorator'

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'thor-modal-material',
    templateUrl: './modal-material.component.html',
    styleUrls: ['./modal-material.component.scss'],
})
// @AutoUnsubscribe
export class ModalMaterialComponent implements OnInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
    componentRef: ComponentRef<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ModalMaterialComponent>,
        private resolver: ComponentFactoryResolver,
    ) { }

    ngOnInit() {
        this.createComponent();
    }

    createComponent() {
        this.container.clear();
        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.data.component);
        this.componentRef = this.container.createComponent(factory);
        const hostedComponent = this.componentRef.instance;

        // pass Inputs to component
        if (this.data.inputs) {
            Object.keys(this.data.inputs).forEach(inputName => {
                hostedComponent[inputName] = this.data.inputs[inputName];
            });
        }

        // PassThrough outputs from hosted component
        Object.keys(hostedComponent)
            .filter(prop => hostedComponent[prop] instanceof EventEmitter)
            .forEach(output => {
                this[output] = new EventEmitter();
                this[`${output}_sub`] = hostedComponent[output].subscribe(data => {
                    this[output].emit(data);
                });
            });
    }

    closeModal() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.componentRef.destroy();
    }
}
