import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { WorkflowService } from '../../../@core/backend/common/services/workflow.service';
import {
    WorkflowTask,
    WorkflowTransition, WorkflowDefinition,
} from '../../../@core/interfaces/common/workflow-definition';
import { filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'ngx-task-operations',
    templateUrl: './task-operations.component.html',
    styleUrls: ['./task-operations.component.scss'],
})
export class TaskOperationsComponent implements OnInit, OnChanges {
    @Input() endPoint: string;
    @Input() wd: WorkflowDefinition;
    @Input() tasks: WorkflowTask<string>[];
    @Output() updatedTasks = new EventEmitter();
    transitions: WorkflowTransition[];
    users: string[] = ['a', 'b'];
    operationsForm = this.fb.group({
        state: [''],
        user: [''],
    });
    constructor(private fb: FormBuilder, private ws: WorkflowService) { }

    ngOnInit() {
        this.operationsForm.get('state').valueChanges.pipe(filter(s => s), switchMap(wfState => {
            return this.ws.updateTasks(this.endPoint, this.tasks.map(t => t.id), { wfState });
        })).subscribe(s => {
            // tslint:disable-next-line: no-console
            console.log(s);
            this.updatedTasks.emit('');
        }, e => {
            // tslint:disable-next-line: no-console
            console.log(e);
            this.operationsForm.reset({ user: '', state: '' });
        });

        this.operationsForm.get('user').valueChanges.pipe(filter(u => u), switchMap(assignee => {
            return this.ws.updateTasks(this.endPoint, this.tasks.map(t => t.id), { assignee });
        })).subscribe(u => {
            // tslint:disable-next-line: no-console
            console.log(u);
            this.updatedTasks.emit('');
        }, e => {
            // tslint:disable-next-line: no-console
            console.log(e);
            this.operationsForm.reset({ user: '', state: '' });
        });
    }

    ngOnChanges() {
        this.transitions = this.ws.getTransitions(this.wd, this.tasks.map(t => t.wfState));
    }

}
