import { Observable } from 'rxjs';
import { User } from './users';
// import { Address } from 'cluster';
// import { Team } from './teams';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { Settings } from './settings';

export interface Role {
    id: string;
    type: string;
    user: User;
    team: any;
}


export abstract class RoleData {
    // abstract get gridDataSource(): DataSource;
    // abstract getCurrentUser(): Observable<User>;
    abstract list(): Observable<Role[]>;
    abstract getByTeam(orgId: string): Observable<Role[]>;
    abstract getByUser(userId: string): Observable<Role[]>;
    abstract create(role: Role): Observable<Role>;
    abstract getByUserAndTeam(usetrId: string, teamId: string): Observable<Role[]>;
    // abstract get(id: string): Observable<User>;
    // abstract update(user: User): Observable<User>;
    // abstract updateCurrent(user: User): Observable<User>;
    // abstract create(user: User): Observable<User>;
    abstract delete(id: string): Observable<boolean>;
    abstract updateRole(id: string, newRole: Role): Observable<Role>;
}
