import { Pipe, PipeTransform } from '@angular/core';
import { WorkflowTask, WorkflowTaskFilter } from '../../@core/interfaces/common/workflow-definition';
import { WorkflowService } from '../../@core/backend/common/services/workflow.service';

@Pipe({
    name: 'filterTasks',
    pure: false,
})
export class FilterTasksPipe implements PipeTransform {
    constructor(private ws: WorkflowService) { }

    transform(tasks: WorkflowTask<string>[], filter: WorkflowTaskFilter, selected: number[]): any {
        return tasks.map((t, i) =>
            ({ ...t, i, checked: selected.includes(i) }),
        ).filter(t => this.ws.matchFilter(t, filter));
    }

}
