import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { WorkflowDefinition, WorkflowTask } from '../../../interfaces/common/workflow-definition';

@Injectable()
export class WorkflowApi {
    private readonly apiController: string = 'workflow/definition';
    private readonly apiCTasks = (endPoint: string) => `projects/${endPoint}/records`;
    constructor(private api: HttpService) { }

    getDefinitions(profile: any): Observable<WorkflowDefinition[]> {
        // TODO: Add userId and teamId in backend for workflowDefinitions
        if (profile.type === 'team') {
            // return this.api.get(this.apiController + '?teamId=' + profile.id);
            return this.api.get(this.apiController + '?workspaceId=' + profile.id);
        } else if (profile.type === 'user') {
            return this.api.get(this.apiController + '?userId=' + profile.id);
        }
    }

    getDefinition(id: string): Observable<WorkflowDefinition> {
        return this.api.get(`${this.apiController}/${id}`);
    }

    createDefinition(wd: WorkflowDefinition): Observable<WorkflowDefinition> {
        return this.api.post(`${this.apiController}`, wd);
    }

    updateDefinition(id: string, wd: WorkflowDefinition): Observable<WorkflowDefinition> {
        return this.api.put(`${this.apiController}/${id}`, wd);
    }

    deleteDefinition(id: string): Observable<string> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    getTasksSummary(endPoint: string, query?: object, groupFields?: string[]): Observable<any> {
        return this.api.post(`${this.apiCTasks(endPoint)}/summary`, { query, groupFields });
    }

    // getTasks(endPoint: string, projectId: string): Observable<WorkflowTask<string>[]> {
    getTasks(endPoint: string, filters: object): Observable<WorkflowTask<string>[]> {
        return this.api.get(this.apiCTasks(endPoint), { params: filters });
    }

    getTask(endPoint: string, taskId: string) {
        return this.api.get(`${this.apiCTasks(endPoint)}/${taskId}`);
    }

    createTask(endPoint: string, task: WorkflowTask<string>) {
        return this.api.post(this.apiCTasks(endPoint), task);
    }

    updateTask(endPoint: string, task: WorkflowTask<string>) {
        return this.api.put(`${this.apiCTasks(endPoint)}/${task.id}`, task);
    }

    deleteTask(endPoint: string, taskId: string) {
        return this.api.delete(`${this.apiCTasks(endPoint)}/${taskId}`);
    }

    createTasks(endPoint: string, tasks: WorkflowTask<string>[]) {
        return this.api.post(`${this.apiCTasks(endPoint)}/bulk`, tasks);
    }

    updateTasks(endPoint: string, ids: string[], update: object) {
        return this.api.put(`${this.apiCTasks(endPoint)}/bulk`, { ids, update });
    }

    deleteTasks(endPoint: string) {
        return this.api.delete(`${this.apiCTasks(endPoint)}/bulk`);
    }

}
