import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Pattern } from '../../../interfaces/common/patterns';

@Injectable()
export class PatternsApi {
    private readonly apiController: string = 'patterns';
    constructor(private api: HttpService) { }

    getPatterns(userId: string, teamId: string): Observable<Pattern[]> {
        let url = `${this.apiController}?`;
        if (userId !== undefined) { url += `userId=${userId}&`; }
        if (teamId !== undefined) { url += `teamId=${teamId}&`; }
        return this.api.get(url);
    }

    // findRecords(userId: string, teamId: string, folder: string): Observable<BriefcaseRecord[]> {
    //     let url = `${this.apiController}?`;
    //     if (userId) { url +=  `userId=${userId}&`; }
    //     if (teamId) { url +=  `teamId=${teamId}&`; }
    //     if (folder) { url +=  `folder=${folder}`; }
    //     return this.api.get(url);
    // }

    // getFolders(userId: string, teamId: string): Observable<any[]> {
    //     let url = `${this.apiController}/folders?`;
    //     if (userId) { url +=  `userId=${userId}&`; }
    //     if (teamId) { url +=  `teamId=${teamId}&`; }
    //     return this.api.get(url);
    // }

    // addRecord(record: BriefcaseRecord) {
    //     return this.api.post(this.apiController, record);
    // }

    // addRecords(record: BriefcaseRecord[]) {
    //     return this.api.post(this.apiController + '/bulk', record);
    // }

    // updateRecord(record: BriefcaseRecord) {
    //     return this.api.put(this.apiController + '/' + record.id, record);
    // }

    // deleteRecord(record: BriefcaseRecord): Observable<boolean> {
    //     return this.api.delete(this.apiController + '/' + record.id);
    // }

    // moveRecords(userId: string, teamId: string, folder: string,
    //             toUserId: string, toTeamId: string, toFolder: string) {
    //     let url = `${this.apiController}/move?`;
    //     if (userId) { url +=  `userId=${userId}&`; }
    //     if (teamId) { url +=  `teamId=${teamId}&`; }
    //     if (folder) { url +=  `folder=${folder}&`; }
    //     if (toUserId) { url +=  `toUserId=${toUserId}&`; }
    //     if (toTeamId) { url +=  `toTeamId=${toTeamId}&`; }
    //     if (toFolder) { url +=  `toFolder=${toFolder}`; }
    //     return this.api.post(url, {});
    // }

    // copyRecords(userId: string, teamId: string, folder: string,
    //             toUserId: string, toTeamId: string, toFolder: string) {
    //     let url = `${this.apiController}/copy?`;
    //     if (userId) { url +=  `userId=${userId}&`; }
    //     if (teamId) { url +=  `teamId=${teamId}&`; }
    //     if (folder) { url +=  `folder=${folder}&`; }
    //     if (toUserId) { url +=  `toUserId=${toUserId}&`; }
    //     if (toTeamId) { url +=  `toTeamId=${toTeamId}&`; }
    //     if (toFolder) { url +=  `toFolder=${toFolder}`; }
    //     return this.api.post(url, {});
    // }

    // deleteFolder(userId: string, teamId: string, folder: string) {
    //     let url = `${this.apiController}/folder/${folder}?`;
    //     if (userId) { url +=  `userId=${userId}&`; }
    //     if (teamId) { url +=  `teamId=${teamId}&`; }
    //     // if (folder) { url +=  `folder=${folder}`; }
    //     return this.api.delete(url, {});
    // }

    // insertFromSnowstorm(userId: string, teamId: string, folder: string,
    //     trId: string, term: string, ecl: string) {
    //     let url = `${this.apiController}/insertFromSnowstorm?`;
    //     if (userId) { url +=  `userId=${userId}&`; }
    //     if (teamId) { url +=  `teamId=${teamId}&`; }
    //     if (folder) { url +=  `folder=${folder}&`; }
    //     if (trId) { url +=  `trId=${trId}&`; }
    //     if (term) { url +=  `term=${term}&`; }
    //     if (ecl) { url +=  `ecl=${ecl}`; }
    //     return this.api.post(url, {});
    // }

}
