import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProjectsApi } from '../api/projects.api';
// import { UserData, User } from '../../../interfaces/common/users';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { NbAuthService } from '@nebular/auth';
// import { switchMap, map } from 'rxjs/operators';
import { ProjectData, Project } from '../../../interfaces/common/projects';

@Injectable()
export class ProjectService extends ProjectData {

    public projectsUpdated = new BehaviorSubject<boolean>(false);
    public projectsUpdatedEvent = this.projectsUpdated.asObservable();

    constructor(private api: ProjectsApi) {
        super();
    }

    updateEvent() {
        this.projectsUpdated.next(true);
        this.projectsUpdated.next(false);
    }

    list(): Observable<Project[]> {
        return this.api.list();
    }

    listProjectsByUser(userId: string, type?: string): Observable<Project[]> {
        return this.api.listProjectsByUser(userId, type);
    }

    listProjectsByTeam(orgId: string, type?: string): Observable<Project[]> {
        return this.api.listProjectsByTeam(orgId, type);
    }

    listProjectsByWorkspace(workspaceId: string, userId: string, type?: string): Observable<Project[]> {
        return this.api.listProjectsByWorkspace(workspaceId, userId, type);
    }

    get(id: string): Observable<Project> {
        return this.api.get(id);
    }

    create(project: Project): Observable<Project> {
        return this.api.add(project);
    }

    update(id: string, project: Project): Observable<Project> {
        return this.api.update(id, project);
    }

    delete(id: string): any {
        return this.api.delete(id);
    }
}
