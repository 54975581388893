import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
// import { HttpParams } from '@angular/common/http';
// import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class TermspaceApi {
    private readonly apiController: string = 'termspace';
    constructor(private api: HttpService) { }

    getProject(trId: string): Observable<any> {
        const url = `${this.apiController}/${trId}/project`;
        return this.api.get(url);
    }

    getProjects(trId: string): Observable<any[]> {
        const url = `${this.apiController}/${trId}/projects`;
        return this.api.get(url);
    }

    searchConcepts(trId: string, query: string, skipTo: number,
        returnLimit: number, lang: string): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        const url = `${this.apiController}/${trId}/concepts?query=${query}&skipTo=${skipTo}&lang=${lang}&returnLimit=${returnLimit}`;
        return this.api.get(url);
    }

    searchConceptsEcl(trId: string, ecl: string, semtag: string, term: string, moduleId: string, skip: number,
        limit: number): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        let url = `${this.apiController}/${trId}/ecl?`;
        if (skip && limit) {
            url += `skip=${skip}&limit=${limit}`;
        }
        return this.api.post(url, { ecl, semtag, term, moduleId });
    }

    getConcept(trId: string, conceptId: string): Observable<any> {
        const url = `${this.apiController}/${trId}/concepts/${conceptId}`;
        return this.api.get(url);
    }

    getChildren(trId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/${trId}/concepts/${conceptId}/children`;
        const reuslt = this.api.get(url);
        return reuslt;
    }

    getAncestors(trId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/${trId}/concepts/${conceptId}/ancestors`;
        const reuslt = this.api.get(url);
        return reuslt;
    }

    getParents(trId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/${trId}/concepts/${conceptId}/parents`;
        const reuslt = this.api.get(url);
        return reuslt;
    }

    deleteTokens(): Observable<any[]> {
        const url = `${this.apiController}/deleteTokens`;
        return this.api.get(url);
    }

    checkPermission(trId: string, username: string, permission: string) {
        const url = `${this.apiController}/${trId}/permissions/${username}/${permission}`;
        return this.api.get(url);
    }

    generateId(trId: string, partitionId: string, namespace: string, limit: number) {
        let url = `${this.apiController}/${trId}/ids/generate?`;
        url += `partitionId=${partitionId}&namespace=${namespace}`;
        if (limit) {
            url += `&limit=${limit}`;
        }
        return this.api.get(url);
    }

    // tslint:disable-next-line: max-line-length
    getDomainAttributes(trId: string, parentIds: string[], proximalPrimitiveModeling?: boolean, contentType?: string): Observable<any[]> {
        let url = `${this.apiController}/domain-attributes?trId=${trId}`;
        let parentsStr = '';
        if (typeof parentIds === 'string') {
            parentsStr = parentIds;
        } else {
            parentsStr = parentIds.join(',');
        }
        url = url + `&parentIds=${parentsStr}`;
        if (typeof proximalPrimitiveModeling === 'undefined') {
            proximalPrimitiveModeling = true;
        }
        if (!contentType) {
            contentType = 'NEW_PRECOORDINATED';
        }
        url = url + `&proximalPrimitiveModeling=${proximalPrimitiveModeling}`;
        url = url + `&contentType=${contentType}`;
        return this.api.get(url);
    }

    getAttributeValues(trId: string, attributeId: string, searchTerm: string, contentType?: string) {
        let url = `${this.apiController}/attribute-values?trId=${trId}`;
        if (!contentType) {
            contentType = 'NEW_PRECOORDINATED';
        }
        url = url + `&contentType=${contentType}`;
        url = url + `&attributeId=${attributeId}`;
        if (searchTerm) {
            url += `&searchTerm=${searchTerm}`;
        }
        return this.api.get(url);
    }

}
