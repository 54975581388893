import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowComponent } from './workflow.component';
import { WorkflowService } from '../../@core/backend/common/services/workflow.service';
import {
    NbListModule,
    NbAccordionModule,
    NbCardModule,
    NbCheckboxModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbSpinnerModule,
} from '@nebular/theme';
import { TaskGroupComponent } from './task-group/task-group.component';
import { TaskListComponent } from './task-list/task-list.component';
import { FilterTasksPipe } from './filter-tasks.pipe';
import {
    MatRippleModule,
    MatTreeModule, MatIconModule, MatButtonModule, MatMenuModule, MatSidenavModule,
    MatProgressBarModule, MatCheckboxModule, MatSelectModule, MatInputModule, MatFormFieldModule, MatTooltipModule,
} from '@angular/material';
import { WorkflowTreeComponent } from './workflow-tree/workflow-tree.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TaskOperationsComponent } from './task-operations/task-operations.component';

@NgModule({
    declarations: [WorkflowComponent, TaskGroupComponent,
        TaskListComponent, FilterTasksPipe, WorkflowTreeComponent,
        TaskOperationsComponent,
    ],
    imports: [
        CommonModule,
        NbListModule,
        NbAccordionModule,
        NbCardModule,
        NbCheckboxModule,
        NbSelectModule,
        NbInputModule,
        NbButtonModule,
        NbSpinnerModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatRippleModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatMenuModule,
        MatTooltipModule,
    ],
    providers: [WorkflowService],
    exports: [WorkflowComponent],
})
export class WorkflowModule { }
