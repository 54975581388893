import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workspace } from '../../../interfaces/common/workspaces';
import { WorkspacesApi } from '../api/workspaces.api';

@Injectable()
export class WorkspaceService {

    constructor(
        private api: WorkspacesApi,
        ) {
        // super();
    }

    list(userId: string): Observable<Workspace[]> {
        return this.api.list(userId);
    }

    get(id: string, fullSources?: boolean): Observable<Workspace> {
        return this.api.get(id, fullSources);
    }

    create(team: Workspace): Observable<Workspace> {
        return this.api.add(team);
    }

    getTerminologyResources(workspaceId: string): Observable<any[]> {
        return this.api.getTerminologyResources(workspaceId);
    }

    update(id: string, workspace: Workspace): Observable<Workspace> {
        return this.api.update(id, workspace);
    }

}
