import { Component, OnInit, Input, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { WorkflowService } from '../../../@core/backend/common/services/workflow.service';
import {
    WorkflowOptions, WorkflowTreeNode,
    WorkflowDefinition,
} from '../../../@core/interfaces/common/workflow-definition';
import { FormBuilder } from '@angular/forms';
import { debounceTime, switchMap, retryWhen, tap } from 'rxjs/operators';
import { UsersService } from '../../../@core/backend/common/services/users.service';
import { User } from '../../../@core/interfaces/common/users';

// states
//     state1
//         allwlists
//         wlists
// wlists
//     wlist1
//         allstates
//         states

@Component({
    selector: 'ngx-workflow-tree',
    templateUrl: './workflow-tree.component.html',
    styleUrls: ['./workflow-tree.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowTreeComponent implements OnInit, OnChanges {
    @Input() taskDisplay: TemplateRef<any>;
    @Input() options: WorkflowOptions;
    @Input() refreshTrigger: any;
    wd: WorkflowDefinition;
    treeControl = new NestedTreeControl<WorkflowTreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<WorkflowTreeNode>();
    checklistSelection = new SelectionModel<WorkflowTreeNode>(true /* multiple */);
    private filtersOpen = [];
    filterForm = this.fb.group({
        assignee: [''],
        wfState: [''],
        workList: [''],
        text: [''],
    });
    userOptions: User[];
    stateOptions: string[];
    wlistOptions: string[];
    loading: boolean = true;
    empty: boolean;
    private userId: string;
    admin: boolean = false;
    total: number;
    lastFilterForm = {};

    constructor(private fb: FormBuilder, private ws: WorkflowService,
        private us: UsersService,
        // private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.us.getCurrentUser().subscribe(u => {
            this.admin = u.role === 'user';
            this.userOptions = [u];
            this.userId = u.id;
            this.filterForm.patchValue({ ...this.lastFilterForm, assignee: this.userId });
        });
        this.ws.getDefinition(this.options.workflowDefintionId).subscribe(wd => this.wd = wd);
        // const initialValue = {
        // assignee: 'actual',
        // };
        this.filterForm.valueChanges.pipe(debounceTime(600),
            // startWith(initialValue),
            switchMap(f => {
                this.lastFilterForm = f;
                const filter = { projectId: this.options.projectId };
                Object.keys(f).forEach(k => {
                    const v = f[k];
                    if (v) {
                        filter[k] = v;
                    }
                });
                this.loading = true;
                // this.cdr.detectChanges();
                return this.ws.getTasksSummary(this.options.endPoint, filter);
            }), retryWhen(e => {
                return e.pipe(tap(() => {
                    // tslint:disable-next-line: no-console
                    console.log('Error on loading summary');
                }));
            })).subscribe(ts => {
                this.loading = false;
                ts.nodes.forEach(node => {
                    node.children.forEach(nodeC => {
                        if (nodeC.children.length > 1) {
                            nodeC.count = nodeC.children.filter((e, i) => i > 0)
                                .reduce((sum: number, current: WorkflowTreeNode) => sum + current.count, 0);
                        } else {
                            nodeC.count = nodeC.children[0].count;
                        }
                    });
                });
                this.total = ts.nodes[0].children.reduce(
                    (sum: number, current: WorkflowTreeNode) => sum + current.count, 0);
                this.empty = ts.nodes.every(n => n.children.length === 0);
                this.treeControl.collapseAll();
                this.dataSource.data = ts.nodes;
                this.stateOptions = ts.filters.states;
                this.wlistOptions = ts.filters.wlists;
                this.expandPreviouslyOpenedNodes();
                // this.cdr.detectChanges();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.refreshTrigger) {
            this.loadTasks();
        }
    }

    loadTasks() {
        const f = this.lastFilterForm;
        const filter = { projectId: this.options.projectId };
        Object.keys(f).forEach(k => {
            const v = f[k];
            if (v) {
                filter[k] = v;
            }
        });
        this.ws.getTasksSummary(this.options.endPoint, filter).subscribe( ts => {
            this.loading = false;
            ts.nodes.forEach(node => {
                node.children.forEach(nodeC => {
                    if (nodeC.children.length > 1) {
                        nodeC.count = nodeC.children.filter((e, i) => i > 0)
                            .reduce((sum: number, current: WorkflowTreeNode) => sum + current.count, 0);
                    } else {
                        nodeC.count = nodeC.children[0].count;
                    }
                });
            });
            this.total = ts.nodes[0].children.reduce(
                (sum: number, current: WorkflowTreeNode) => sum + current.count, 0);
            this.empty = ts.nodes.every(n => n.children.length === 0);
            this.treeControl.collapseAll();
            this.dataSource.data = ts.nodes;
            this.stateOptions = ts.filters.states;
            this.wlistOptions = ts.filters.wlists;
            this.expandPreviouslyOpenedNodes();
        });
    }

    toggleReadOnly = () => this.options.readOnly = !this.options.readOnly;

    filterAssignee = (onlyMine: boolean = false) => this.filterForm.patchValue({
        ...this.lastFilterForm,
        assignee: onlyMine && this.userId,
    })

    refresh() {
        this.filterForm.patchValue(this.lastFilterForm);
    }

    getActualFiltersOpen() {
        this.filtersOpen = [];
        const getFiltersRecursive = (n: WorkflowTreeNode): void => {
            if (typeof n.children !== 'undefined' && this.treeControl.isExpanded(n)) {
                if (n.filters) {
                    this.filtersOpen.push(JSON.stringify(n.filters));
                } else {
                    n.children.forEach(c => {
                        getFiltersRecursive(c);
                    });
                }
            }
        };
        this.dataSource.data.forEach(d => {
            getFiltersRecursive(d);
        });
    }

    expandPreviouslyOpenedNodes() {
        const hasDescendantMatchingFilter = (n: WorkflowTreeNode): boolean => {
            if (n.filters) {
                return this.filtersOpen.includes(JSON.stringify(n.filters));
            } else {
                if (n.children) {
                    return n.children.map((c: WorkflowTreeNode) => hasDescendantMatchingFilter(c)).includes(true);
                } else {
                    return false;
                }
            }
        };
        let refreshing = false;
        const expandRecursive = (n: WorkflowTreeNode) => {
            if (typeof n.children !== 'undefined') {
                const shouldExpand = hasDescendantMatchingFilter(n);
                if (shouldExpand) {
                    if (typeof n.filters !== 'undefined') {
                        this.clickTreeNode(n, true);
                        refreshing = true;
                    }
                    this.treeControl.expand(n);
                    n.children.forEach((c: WorkflowTreeNode) => {
                        expandRecursive(c);
                    });
                }
            }
        };
        this.dataSource.data.forEach(d => {
            if (this.filtersOpen.length) {
                expandRecursive(d);
            } else {
                this.treeControl.expand(d);
            }
        });
        if (!refreshing) {
            this.refreshTree();
        }
    }

    getSelectedChildren(node: WorkflowTreeNode) {
        return node.children.filter(c => this.checklistSelection.isSelected(c));
    }

    todoLeafItemSelectionToggle(node: WorkflowTreeNode): void {
        this.checklistSelection.toggle(node);
    }

    todoItemSelectionToggle(node: WorkflowTreeNode, selected: boolean): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        !selected
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
        // descendants.every(child =>
        //     this.checklistSelection.isSelected(child),
        // );
    }

    descendantsAllSelected(node: WorkflowTreeNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length && !node.moreToLoad && descendants.every(child =>
            this.checklistSelection.isSelected(child),
        );
        return descAllSelected;
    }

    descendantsAtLeastOneSelected(node: WorkflowTreeNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.length && descendants.some(child => this.checklistSelection.isSelected(child));
    }
    descendantsPartiallySelected(node: WorkflowTreeNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.length && descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    clickTreeNode(node: WorkflowTreeNode, opening: boolean, doneByUser: boolean = false) {
        if (doneByUser && !!node.filters) {
            this.getActualFiltersOpen();
        }
        if (opening && !node.loading && !!node.filters && node.children.length < node.count) {
            node.loading = true;
            this.refreshTree();
            const { projectId } = this.options;
            const skip: number = node.children.length;
            const limit: number = this.options.limit || 100;
            const filters = { ...node.filters, projectId, skip, limit };
            Object.keys(this.lastFilterForm).forEach(k => {
                const v = this.lastFilterForm[k];
                if (v) {
                    filters[k] = v;
                }
            });
            this.ws.getTasks(this.options.endPoint, filters).subscribe(tasks => {
                // node.children = node.children.concat(tasks.map(t => ({ ...t, text: t.display })));
                node.children = node.children.concat(tasks.map(t => t));
                node.moreToLoad = node.children.length < node.count;
                node.loading = false;
                this.refreshTree();
            }, error => {
                // tslint:disable-next-line: no-console
                console.log(error);
                node.loading = false;
                this.treeControl.collapse(node);
                this.refreshTree();
            });
        }
    }

    private refreshTree() {
        const _data = this.dataSource.data;
        this.dataSource.data = null;
        this.dataSource.data = _data;
    }

    hasChild = (_: number, node: WorkflowTreeNode) => typeof node.children !== 'undefined';
}
