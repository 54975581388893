import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SctIdsApi } from '../api/sctids.api';
import { SctId } from '../../../interfaces/common/sctids';

@Injectable()
export class SctIdsService {

    constructor(private api: SctIdsApi) { }

    get(namespace: string, partition: string): Observable<SctId> {
        return this.api.get(namespace, partition);
    }

    getCommunityConceptId(quantity?: number): Observable<SctId[]> {
        return this.api.getCommunityConceptId(quantity);
    }

    getCommunityDescriptionId(quantity?: number): Observable<SctId[]> {
        return this.api.getCommunityDescriptionId(quantity);
    }

    getCommunityRelationshipId(quantity?: number): Observable<SctId[]> {
        return this.api.getCommunityRelationshipId(quantity);
    }
}
