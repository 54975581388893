import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class ActivityApi {
    private readonly apiController: string = 'activity';
    constructor(private api: HttpService) { }

    getActivity(userId: string, teamId: string): Observable<any[]> {
        let url = `${this.apiController}?`;
        if (userId) {
            url = url + `userId=${userId}&`;
        }
        if (teamId) {
            url = url + `teamId=${teamId}&`;
        }
        return this.api.get(url);
    }
}
