import {
    Component,
    OnInit, Input, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter,
} from '@angular/core';
import {
    WorkflowTask,
    WorkflowTransition,
    WorkflowDefinition,
    WorkflowTaskFilter,
    WorkflowOptions,
} from '../../../@core/interfaces/common/workflow-definition';
import { WorkflowService } from '../../../@core/backend/common/services/workflow.service';
import { User } from '../../../@core/interfaces/common/users';

@Component({
    selector: 'ngx-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListComponent implements OnInit {
    @Input() tasks: WorkflowTask<string>[];
    @Input() taskDisplay: TemplateRef<any>;
    @Input() options: WorkflowOptions;
    @Output() updatedTasks = new EventEmitter<WorkflowTask<string>[]>();
    private currentStates: string[];
    private wd: WorkflowDefinition;
    loading: boolean;
    filters: WorkflowTaskFilter;
    userOptions: string[];
    stateOptions: string[];
    wListOptions: string[];
    selectedTasks: number[];
    allChecked: boolean;
    availableTransitions: WorkflowTransition[];
    users: User[] | any = [{ login: 'Test' }]; // needs a method to get possible users

    constructor(private ws: WorkflowService, private cdr: ChangeDetectorRef) { }

    private distinctF = (e, i, arr) => arr.findIndex(eL => eL === e) === i;

    ngOnInit(): void {
        this.loading = true;
        this.filters = {};
        this.selectedTasks = [];
        this.allChecked = false;
        this.currentStates = [];
        this.availableTransitions = [];
        this.stateOptions = this.tasks.map(t => t.wfState).filter(this.distinctF);
        this.userOptions = this.tasks.map(t => t.assignee.login).filter(this.distinctF);
        this.wListOptions = this.tasks.map(t => t.workList).filter(this.distinctF);
        this.ws.getDefinition(this.options.workflowDefintionId).subscribe(wd => {
            this.wd = wd;
            this.loading = false;
            this.cdr.detectChanges();
        });
    }

    updateTasks(tasks: WorkflowTask<string>[]) {
        this.updatedTasks.emit(tasks);
    }

    applyFilter(field: string, value: string) {
        this.selectedTasks = [];
        this.allChecked = false;
        this.filters[field] = value;
    }

    toggleAll() {
        this.selectedTasks = this.allChecked ? [] : this.tasks.map((t, i) => i).filter(t =>
            this.ws.matchFilter(this.tasks[t], this.filters));
        this.allChecked = !this.allChecked;
        this.getCurrentStates();
        this.getAvailableTransitions();
    }

    getTaskIndex = (id: string) => this.tasks.findIndex(t => t.id === id);

    getCurrentStates = () => {
        this.currentStates = this.selectedTasks.map(t => this.tasks[t].wfState).filter(this.distinctF);
    }

    getAvailableTransitions = () => {
        this.availableTransitions = this.currentStates.length ?
            this.ws.getTransitions(this.wd, this.currentStates) : [];
    }

    changedTransition(selectedTransition: WorkflowTransition) {
        // tslint:disable-next-line: no-console
        this.ws.updateTasks(this.options.endPoint,
            this.tasks.filter((t, i) => this.selectedTasks.includes(i))
                .map(t => t.id), { wfState: selectedTransition.to }).subscribe(u => {
                    this.updateTasks(this.tasks.filter((t, i) => this.selectedTasks.includes(i))
                        .map(t => ({ ...t, wfState: selectedTransition.to })));
                });
    }

    changedUser(selectedUser: User) {
        // tslint:disable-next-line: no-console
        this.ws.updateTasks(this.options.endPoint,
            this.tasks.filter((t, i) => this.selectedTasks.includes(i))
                .map(t => t.id), { assignee: selectedUser.id }).subscribe(u => {
                    this.updateTasks(this.tasks.filter((t, i) => this.selectedTasks.includes(i))
                        .map(t => ({ ...t, assignee: selectedUser.id })));
                });
    }

    select(task: number, add: boolean) {
        if (add) {
            this.selectedTasks.push(task);
            if (!this.currentStates.includes(this.tasks[task].wfState)) {
                this.currentStates.push(this.tasks[task].wfState);
                this.getAvailableTransitions();
            }
            this.allChecked = this.selectedTasks.length ===
                this.tasks.filter(t => this.ws.matchFilter(t, this.filters)).length;
        } else {
            this.allChecked = false;
            this.selectedTasks = this.selectedTasks.filter(t => task !== t);
            this.getCurrentStates();
            this.getAvailableTransitions();
        }
    }

}
