import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
// tslint:disable-next-line: max-line-length
import { Subset, SimpleMap, AdvancedMap, Refset, ConceptExtension, SnomedExtension, AuthoringProject } from '../../../interfaces/common/projects';

@Injectable()
export class ProjectDetailsApi {
    private readonly apiController: string = 'projects';

    constructor(private api: HttpService) { }

    // Subset API
    listSubsets(projectId?: string): Observable<any[]> {
        let url = `${this.apiController}/subset/details`;
        if (projectId) {
            url += `?projectId=${projectId}`;
        }
        return this.api.get(url);
    }

    listSubsetsByUser(userId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/subset/details?userId=${userId}`);
    }

    listSubsetsByTeam(teamId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/subset/details?teamId=${teamId}`);
    }

    getSubset(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/subset/details/${id}`);
    }

    addSubset(item: Subset): Observable<any> {
        return this.api.post(`${this.apiController}/subset/details`, item);
    }

    updateSubset(id: string, item: Subset): Observable<any> {
        return this.api.put(`${this.apiController}/subset/details/${id}`, item);
    }

    deleteSubset(id: string): any {
        return this.api.delete(`${this.apiController}/subset/details/${id}`);
    }

    // Simple Map API
    listSimpleMaps(projectId?: string): Observable<any[]> {
        let url = `${this.apiController}/simplemap/details`;
        if (projectId) {
            url += `?projectId=${projectId}`;
        }
        return this.api.get(url);
    }

    listSimpleMapsByUser(userId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/simplemap/details?userId=${userId}`);
    }

    listSimpleMapsByTeam(teamId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/simplemap/details?teamId=${teamId}`);
    }

    getSimpleMap(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/simplemap/details/${id}`);
    }

    addSimpleMap(item: SimpleMap): Observable<any> {
        return this.api.post(`${this.apiController}/simplemap/details`, item);
    }

    updateSimpleMap(id: string, item: SimpleMap): Observable<any> {
        return this.api.put(`${this.apiController}/simplemap/details/${id}`, item);
    }

    deleteSimpleMap(id: string): any {
        return this.api.delete(`${this.apiController}/simplemap/details/${id}`);
    }


    // Advanced Map API
    listAdvancedMaps(projectId?: string): Observable<any[]> {
        let url = `${this.apiController}/advancedmap/details`;
        if (projectId) {
            url += `?projectId=${projectId}`;
        }
        return this.api.get(url);
    }

    listAdvancedMapsByUser(userId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/advancedmap/details?userId=${userId}`);
    }

    listAdvancedMapsByTeam(teamId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/advancedmap/details?teamId=${teamId}`);
    }

    getAdvancedMap(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/advancedmap/details/${id}`);
    }

    addAdvancedMap(item: AdvancedMap): Observable<any> {
        return this.api.post(`${this.apiController}/advancedmap/details`, item);
    }

    updateAdvancedMap(id: string, item: AdvancedMap): Observable<any> {
        return this.api.put(`${this.apiController}/advancedmap/details/${id}`, item);
    }

    deleteAdvancedMap(id: string): any {
        return this.api.delete(`${this.apiController}/advancedmap/details/${id}`);
    }

    // Refset API
    listRefsets(projectId: string, snomedExtensionId: string): Observable<any[]> {
        let url = `${this.apiController}/refset/details?1=1`;
        if (projectId) {
            url += `&projectId=${projectId}`;
        }
        if (snomedExtensionId) {
            url += `&snomedExtensionId=${snomedExtensionId}`;
        }
        return this.api.get(url);
    }

    listRefsetsByUser(userId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/refset/details?userId=${userId}`);
    }

    listRefsetsByTeam(teamId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/refset/details?teamId=${teamId}`);
    }

    getRefset(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/refset/details/${id}`);
    }

    addRefset(item: Refset): Observable<any> {
        return this.api.post(`${this.apiController}/refset/details`, item);
    }

    updateRefset(id: string, item: Refset): Observable<any> {
        return this.api.put(`${this.apiController}/refset/details/${id}`, item);
    }

    deleteRefset(id: string): any {
        return this.api.delete(`${this.apiController}/refset/details/${id}`);
    }

    // Concept Extension API
    listConceptExtensions(projectId: string, snomedExtensionId: string): Observable<ConceptExtension[]> {
        let url = `${this.apiController}/conceptextension/details?1=1`;
        if (projectId) {
            url += `&projectId=${projectId}`;
        }
        if (snomedExtensionId) {
            url += `&snomedExtensionId=${snomedExtensionId}`;
        }
        return this.api.get(url);    }

    listConceptExtensionsByUser(userId: string): Observable<ConceptExtension[]> {
        return this.api.get(`${this.apiController}/conceptextension/details?userId=${userId}`);
    }

    listConceptExtensionsByTeam(teamId: string): Observable<ConceptExtension[]> {
        return this.api.get(`${this.apiController}/conceptextension/details?teamId=${teamId}`);
    }

    getConceptExtension(id: string): Observable<ConceptExtension> {
        return this.api.get(`${this.apiController}/conceptextension/details/${id}`);
    }

    addConceptExtension(conceptExtension: ConceptExtension): Observable<ConceptExtension> {
        return this.api.post(`${this.apiController}/conceptextension/details`, conceptExtension);
    }

    updateConceptExtension(id: string, conceptExtension: ConceptExtension): Observable<ConceptExtension> {
        return this.api.put(`${this.apiController}/conceptextension/details/${id}`, conceptExtension);
    }

    deleteConceptExtension(id: string): any {
        return this.api.delete(`${this.apiController}/conceptextension/details/${id}`);
    }

    // Snomed Extension API
    listSnomedExtensions(projectId?: string): Observable<SnomedExtension[]> {
        let url = `${this.apiController}/extension/details`;
        if (projectId) {
            url += `?projectId=${projectId}`;
        }
        return this.api.get(url);    }

    listSnomedExtensionsByUser(userId: string): Observable<SnomedExtension[]> {
        return this.api.get(`${this.apiController}/extension/details?userId=${userId}`);
    }

    listSnomedExtensionsByTeam(teamId: string): Observable<SnomedExtension[]> {
        return this.api.get(`${this.apiController}/extension/details?teamId=${teamId}`);
    }

    getSnomedExtension(id: string): Observable<SnomedExtension> {
        return this.api.get(`${this.apiController}/extension/details/${id}`);
    }

    addSnomedExtension(snomedExtension: SnomedExtension): Observable<SnomedExtension> {
        return this.api.post(`${this.apiController}/extension/details`, snomedExtension);
    }

    updateSnomedExtension(id: string, snomedExtension: SnomedExtension): Observable<SnomedExtension> {
        return this.api.put(`${this.apiController}/extension/details/${id}`, snomedExtension);
    }

    deleteSnomedExtension(id: string): any {
        return this.api.delete(`${this.apiController}/extension/details/${id}`);
    }

    // Authoring API
    listAuthoringProjects(projectId?: string): Observable<AuthoringProject[]> {
        let url = `${this.apiController}/authoringProject/details`;
        if (projectId) {
            url += `?projectId=${projectId}`;
        }
        return this.api.get(url);    }

    listAuthoringProjectsByUser(userId: string): Observable<AuthoringProject[]> {
        return this.api.get(`${this.apiController}/authoringProject/details?userId=${userId}`);
    }

    listAuthoringProjectsByTeam(teamId: string): Observable<AuthoringProject[]> {
        return this.api.get(`${this.apiController}/authoringProject/details?teamId=${teamId}`);
    }

    getAuthoringProject(id: string): Observable<AuthoringProject> {
        return this.api.get(`${this.apiController}/authoringProject/details?projectId=${id}`);
    }

    addAuthoringProject(authoringProject: AuthoringProject): Observable<AuthoringProject> {
        return this.api.post(`${this.apiController}/authoringProject/details`, authoringProject);
    }

    updateAuthoringProject(id: string, authoringProject: AuthoringProject): Observable<AuthoringProject> {
        return this.api.put(`${this.apiController}/authoringProject/details/${id}`, authoringProject);
    }

    deleteAuthoringProject(id: string): any {
        return this.api.delete(`${this.apiController}/authoringProject/details/${id}`);
    }
}
