import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { Workspace } from '../../../interfaces/common/workspaces';

@Injectable()
export class WorkspacesApi {
    private readonly apiController: string = 'workspace';

    constructor(private api: HttpService) { }

    list(userId: string): Observable<any[]> {

        return this.api.get(this.apiController + '?userId=' + userId)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    get(id: string, fullSources?: boolean): Observable<any> {
        let fullSourcesApi: boolean;
        if (!fullSources) {
            fullSourcesApi = false;
        } else {
            fullSourcesApi = true;
        }
        return this.api.get(`${this.apiController}/${id}` + '?fullSources=' + fullSources)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    add(item: Workspace): Observable<any> {
        return this.api.post(this.apiController, item);
    }

    update(id: string, item: Workspace): Observable<any> {
        return this.api.put(this.apiController + '/' + id, item);
    }

    getTerminologyResources(workspaceId: string): Observable<any[]> {
        return this.api.get(this.apiController + '/' + workspaceId + '/terminologyResources')
        .pipe(map(data => data.map(item => {
            return { ...item };
        })));
    }
}
