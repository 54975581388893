import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { SubsetRecord, SimpleMapRecord, AdvancedMapRecord, RefsetRecord, ConceptExtensionRecord, AuthoringTask, AuthoringRecord, ReviewMessage, SimpleMapTasklist } from '../../../interfaces/common/projects';
import { ProjectRecordsApi } from '../api/projects.records.api';

@Injectable()
export class ProjectRecordsService {

    constructor(private api: ProjectRecordsApi) {
    }

    // Subset

    listSubsetRecords(projectId: string, active?: string): Observable<any[]> {
        return this.api.listSubsetRecords(projectId, active);
    }

    getSubsetRecord(id: string): Observable<any> {
        return this.api.getSubsetRecord(id);
    }

    addSubsetRecord(item: SubsetRecord): Observable<any> {
        return this.api.addSubsetRecord(item);
    }

    updateSubsetRecord(id: string, item: SubsetRecord): Observable<any> {
        return this.api.updateSubsetRecord(id, item);
    }

    deleteSubsetRecord(id: string): any {
        return this.api.deleteSubsetRecord(id);
    }

    addSnowstormResultsToSubset(projectId: string, trId: string,
        term: string, ecl: string): any {
        return this.api.addSnowstormResultsToSubset(projectId, trId, term, ecl);
    }

    // Simple map

    listSimpleMapTasklists(projectId: string, projectKey?: string, key?: string): Observable<any[]> {
        return this.api.listSimpleMapTasklists(projectId, projectKey, key);
    }

    getSimpleMapTasklist(id: string): Observable<any> {
        return this.api.getSimpleMapTasklist(id);
    }

    addSimpleMapTasklist(item: SimpleMapTasklist): Observable<any> {
        return this.api.addSimpleMapTasklist(item);
    }

    updateSimpleMapTasklist(id: string, item: SimpleMapTasklist): Observable<any> {
        return this.api.updateSimpleMapTasklist(id, item);
    }

    deleteSimpleMapTasklist(id: string): any {
        return this.api.deleteSimpleMapTasklist(id);
    }

    // tslint:disable-next-line: max-line-length
    searchSimpleMapRecords(workspaceId: string, projectId: string, taskListId: string, code: string, text: string,
        workList: string, assignee: string, wfState: string, skip: number, limit: number): Observable<any[]> {
        return this.api.searchSimpleMapRecords(workspaceId, projectId, taskListId, code, text, workList,
            assignee, wfState, skip, limit);
    }

    getSimpleMapRecord(id: string): Observable<any> {
        return this.api.getSimpleMapRecord(id);
    }

    addSimpleMapRecord(item: SimpleMapRecord): Observable<any> {
        return this.api.addSimpleMapRecord(item);
    }

    updateSimpleMapRecord(id: string, item: SimpleMapRecord): Observable<any> {
        return this.api.updateSimpleMapRecord(id, item);
    }

    deleteSimpleMapRecord(id: string): any {
        return this.api.deleteSimpleMapRecord(id);
    }

    createSimpleMapAssignmentsFromBriefcase(taskListId: string, assignTo: string, briefcaseUserId: string,
        briefcaseTeamId: string, briefcaseFolder: string, workList: string): Observable<any> {
        return this.api.createSimpleMapAssignmentsFromBriefcase(taskListId, assignTo, briefcaseUserId,
            briefcaseTeamId, briefcaseFolder, workList);
    }

    // Advanced map

    listAdvancedMapRecords(projectId: string): Observable<any[]> {
        return this.api.listAdvancedMapRecords(projectId);
    }

    getAdvancedMapRecord(id: string): Observable<any> {
        return this.api.getAdvancedMapRecord(id);
    }

    addAdvancedMapRecord(item: AdvancedMapRecord): Observable<any> {
        return this.api.addAdvancedMapRecord(item);
    }

    updateAdvancedMapRecord(id: string, item: AdvancedMapRecord): Observable<any> {
        return this.api.updateAdvancedMapRecord(id, item);
    }

    deleteAdvancedMapRecord(id: string): any {
        return this.api.deleteAdvancedMapRecord(id);
    }

    createAdvancedMapAssignmentsFromBriefcase(projectId: string, assignTo: string, briefcaseUserId: string,
        briefcaseTeamId: string, briefcaseFolder: string, workList: string): Observable<any> {
        return this.api.createAdvancedMapsAssignmentsFromBriefcase(projectId, assignTo, briefcaseUserId,
            briefcaseTeamId, briefcaseFolder, workList);
    }

    // Refset

    listRefsetRecords(projectId: string, active?: string, commit?): Observable<any> {
        return this.api.listRefsetRecords(projectId, active, commit);
    }

    getRefsetRecord(id: string): Observable<any> {
        return this.api.getRefsetRecord(id);
    }

    addRefsetRecord(item: RefsetRecord): Observable<any> {
        return this.api.addRefsetRecord(item);
    }

    updateRefsetRecord(id: string, item: RefsetRecord): Observable<any> {
        return this.api.updateRefsetRecord(id, item);
    }

    inactivateRefsetRecordByCodeId(codeId: string, projectId: string): Observable<any> {
        return this.api.inactivateRefsetRecordByCodeId(codeId, projectId);
    }

    deleteRefsetRecord(id: string): any {
        return this.api.deleteRefsetRecord(id);
    }

    createRefsetRecordsFromBriefcase(workspaceId: string, projectId: string,
        assignTo: string, briefcaseUserId: string,
        briefcaseTeamId: string, briefcaseFolder: string, workList: string): Observable<any> {
        return this.api.createRefsetRecordsFromBriefcase(workspaceId, projectId, assignTo, briefcaseUserId,
            briefcaseTeamId, briefcaseFolder, workList);
    }

    syncWithQuery(projectId: string, trId: string,
        term: string, ecl: string): any {
        return this.api.syncWithQuery(projectId, trId, term, ecl);
    }

    addSnowstormResultsToRefset(projectId: string, trId: string,
        term: string, ecl: string): any {
        return this.api.addSnowstormResultsToRefset(projectId, trId, term, ecl);
    }

    removeSnowstormResultsFromRefset(projectId: string, trId: string,
        term: string, ecl: string): any {
        return this.api.removeSnowstormResultsFromRefset(projectId, trId, term, ecl);
    }

    getCommitSummary(projectId: string): any {
        return this.api.getCommitSummary(projectId);
    }

    bulkUpdate(records: RefsetRecord[], projectId): any {
        return this.api.bulkUpdate(records, projectId);
    }

    uploadToRefset(filename: string, spec: any, projectId: string) {
        return this.api.uploadToRefset(filename, spec, projectId);
    }

    // Concept Extension

    listConceptExtensionRecords(projectId: string, text: string, active: string): Observable<any[]> {
        return this.api.listConceptExtensionRecords(projectId, text, active);
    }

    getConceptExtensionRecord(id: string): Observable<any> {
        return this.api.getConceptExtensionRecord(id);
    }

    addConceptExtensionRecord(item: ConceptExtensionRecord): Observable<any> {
        return this.api.addConceptExtensionRecord(item);
    }

    updateConceptExtensionRecord(id: string, item: ConceptExtensionRecord): Observable<any> {
        return this.api.updateConceptExtensionRecord(id, item);
    }

    deleteConceptExtensionecord(id: string): any {
        return this.api.deleteConceptExtensionRecord(id);
    }

    // Authoring Project

    listAuthoringProjectTasks(projectId: string, key?: number, projectKey?: string): Observable<any[]> {
        return this.api.listAuthoringProjectTasks(projectId, key, projectKey);
    }

    getAuthoringProjectTask(id: string): Observable<any> {
        return this.api.getAuthoringProjectTask(id);
    }

    addAuthoringProjectTask(item: AuthoringTask): Observable<any> {
        return this.api.addAuthoringProjectTask(item);
    }

    updateAuthoringProjectTask(id: string, item: AuthoringTask): Observable<any> {
        return this.api.updateAuthoringProjectTask(id, item);
    }

    listAuthoringProjectRecords(projectId: string, taskId?: string, assignee?: string,
        workList?: string, text?: string, active?: boolean): Observable<any[]> {
        return this.api.listAuthoringProjectRecords(projectId, taskId, assignee, workList, text, active);
    }

    getAuthoringProjectRecord(id: string): Observable<any> {
        return this.api.getAuthoringProjectRecord(id);
    }

    addAuthoringProjectRecord(item: AuthoringRecord): Observable<any> {
        return this.api.addAuthoringProjectRecord(item);
    }

    updateAuthoringProjectRecord(id: string, item: AuthoringRecord): Observable<any> {
        return this.api.updateAuthoringProjectRecord(id, item);
    }

    // Review Messages / Feedback API
    listAuthoringProjectTaskMessages(taskId: string, conceptId: string): Observable<any[]> {
        return this.api.listAuthoringProjectTaskMessages(taskId, conceptId);
    }

    getAuthoringProjectTaskMessage(id: string): Observable<any> {
        return this.api.getAuthoringProjectTaskMessage(id);
    }

    addAuthoringProjectTaskMessage(item: ReviewMessage): Observable<any> {
        return this.api.addAuthoringProjectTaskMessage(item);
    }

    updateAuthoringProjectTaskMessage(id: string, item: ReviewMessage): Observable<any> {
        return this.api.updateAuthoringProjectTaskMessage(id, item);
    }

}
