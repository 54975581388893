import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SnowstormApi } from '../api/snowstorm.api';

@Injectable()
export class SnowstormService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: SnowstormApi) {
    }

    searchConcepts(trId: string, term: string, ecl: string,
        offset: number, limit: number, searchAfter?: string): Observable<any> {
        return this.api.searchConcepts(trId, term, ecl, offset, limit, searchAfter);
    }

    getConcept(trId: string, conceptId: string): Observable<any> {
        return this.api.getConcept(trId, conceptId);
    }

    getChildren(trId: string, conceptId: string): Observable<any[]> {
        return this.api.getChildren(trId, conceptId);
    }

    getAncestors(trId: string, conceptId: string): Observable<any[]> {
        return this.api.getAncestors(trId, conceptId);
    }

    getParents(trId: string, conceptId: string): Observable<any[]> {
        return this.api.getParents(trId, conceptId);
    }

    getBranches(trId: string): Observable<any[]> {
        return this.api.getBranches(trId);
    }

    getMembers(trId: string, refsetId: string,
        offset: number, limit: number): Observable<any> {
        return this.api.getMembers(trId, refsetId, offset, limit);
    }

    // tslint:disable-next-line: max-line-length
    getDomainAttributes(trId: string, parentIds: string[], proximalPrimitiveModeling: boolean, contentType: string): Observable<any> {
        return this.api.getDomainAttributes(trId, parentIds, proximalPrimitiveModeling, contentType);
    }

    getAttributeValues(trId: string, contentType: string, attributeId: string, searchTerm: string) {
        return this.api.getAttributeValues(trId, attributeId, searchTerm, contentType);
    }
}
