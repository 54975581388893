import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryApi } from '../api/query.api';

@Injectable()
export class QueryService {

    constructor(private api: QueryApi) {
    }

    getQueries(workspaceId: string, status: string): Observable<any[]> {
        return this.api.getQueries(workspaceId, status);
    }

    createQuery(query: any): Observable<any> {
        return this.api.createQuery(query);
    }

    getQuery(id: string): Observable<any> {
        return this.api.getQuery(id);
    }

    updateQuery(query: any): Observable<any> {
        return this.api.updateQuery(query);
    }

    deleteQuery(id: string): Observable<any> {
        return this.api.deleteQuery(id);
    }

}
