import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService,
    NbToastrService,
    NbGlobalPosition,
    NbGlobalPhysicalPosition,
} from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { AppStore } from '../../../@core/stores/app.store';
import { Profile } from '../../../@core/interfaces/common/profiles';
import { RoleService } from '../../../@core/backend/common/services/roles.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BriefcaseService } from '../../../@core/backend/common/services/briefcase.service';
import { MatDialog } from '@angular/material';
import { CreateWorkspaceComponent } from '../create-workspace/create-workspace.component';
import { WorkspaceService } from '../../../@core/backend/common/services/workspaces.service';
import { WorkflowService } from '../../../@core/backend/common/services/workflow.service';

@Component({
    selector: 'ngx-header-slim',
    styleUrls: ['./header-slim.component.scss'],
    templateUrl: './header-slim.component.html',
})
export class SlimHeaderComponent implements OnInit, OnDestroy {

    @ViewChild('dialogRef', { static: false }) dialogRef: TemplateRef<any>;
    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = true;
    user: any;
    private subscriptions = new Subscription();
    currentProfile: Profile;

    availableProfiles: Profile[] = [];

    currentTheme = 'default';

    userMenu = this.getMenuItems();

    briefcaseCount = 0;

    position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;

    queryParams: Params;

    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
        {
            value: 'cosmic',
            name: 'Cosmic',
        },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];

    constructor(private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private appStore: AppStore,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private toastrService: NbToastrService,
        private roleService: RoleService,
        protected router: Router,
        private route: ActivatedRoute,
        private briefcaseService: BriefcaseService,
        private matDialog: MatDialog,
        private workspaceService: WorkspaceService,
        private workflowService: WorkflowService,
    ) {

    }

    ngOnInit() {

        this.currentTheme = this.themeService.currentTheme;

        this.userMenu = this.getMenuItems();
        this.loadUser();
        this.loadWorkspaces();

        this.subscriptions.add(
            this.appStore.getProfile().subscribe(profile => {
                this.currentProfile = profile;
                this.updateBriefcaseCount();
                this.updateParams();
            }),
        );
        this.subscriptions.add(
            this.briefcaseService.briefcaseUpdatedEvent.subscribe(updated => {
                if (updated) {
                    this.updateBriefcaseCount();
                }
            }),
        );
        this.subscriptions.add(
            this.roleService.rolesUpdatedEvent.subscribe(updated => {
                if (updated) {
                    this.loadWorkspaces();
                }
            }),
        );


        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$),
            )
            .subscribe(themeName => this.currentTheme = themeName);
    }

    updateBriefcaseCount() {
        this.briefcaseCount = 0;
        if (this.currentProfile.type === 'user') {
            this.briefcaseService.findRecords(this.currentProfile.id, null, 'root').subscribe(result => {
                this.briefcaseCount = result.length;
            });
        } else if (this.currentProfile.type === 'team') {
            this.briefcaseService.findRecords(null, this.currentProfile.id, 'root').subscribe(result => {
                this.briefcaseCount = result.length;
            });
        }
    }

    setProfile(e, alert?) {
        if (e !== 'newWorkspace') {
            this.appStore.setProfile(e);
            this.subscriptions.add(
                this.appStore.getProfile().subscribe(profile => {
                    if (profile.type === 'user') {
                        console.error('Invalid workspace');
                    } else {
                    this.currentProfile = profile;
                    }
                }),
            );
            // this.updateParams();
            if (alert) {
                this.alertProfileChange();
            }
        } else {
            this.createWorkspace();
        }
    }

    alertProfileChange() {
        this.toastrService.info('Using ' + this.currentProfile.name + ' workspace!', 'Info',
            {
                icon: 'info-outline',
                position: this.position,
            });
        this.router.navigate(
            ['/pages/wsdashboard'],
            {
                relativeTo: this.route,
                queryParams: this.queryParams,
                replaceUrl: true,
                // queryParamsHandling: 'merge',
            });
    }

    updateParams() {
        // let queryParams: Params;
        if (this.currentProfile && this.currentProfile.type === 'team') {
            // if (this.currentProfile.type === 'user') {
            //     // this.queryParams = { userId: this.currentProfile.id };
            // } else {
            //     this.queryParams = { workspaceId: this.currentProfile.id };
            // }
            this.queryParams = { workspaceId: this.currentProfile.id };
            this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    queryParams: this.queryParams,
                    replaceUrl: true,
                    // queryParamsHandling: 'merge',
                },
            );
        }
    }

    getMenuItems() {
        const userLink = this.user ? '/pages/users/current/' : '';
        return [
            { title: 'Profile', link: userLink, queryParams: { profile: true } },
            { title: 'Log out', link: '/auth/logout' },
        ];
    }

    loadUser() {
        // this.availableProfiles = [];
        this.user = this.appStore.getUser();
        // const userProfile: Profile = {
        //     name: this.user.login,
        //     type: 'user',
        //     id: this.user.id,
        // };
        // this.currentProfile = this.availableProfiles[0];
        // this.route.queryParams.subscribe(params => {
        //     if (!params.teamId) {
        //         this.updateParams();
        //     }
        // });
        // this.availableProfiles.push(userProfile);
    }

    createFirstWorkspace() {
        this.subscriptions.add(
            this.workspaceService.create({
                userId: this.user.id,
                name: this.user.login + ' Workspace',
                type: 'codesystem',
                sourceTerminologyResources: [],
                rf2ExportConfig: null,
                fhirExportConfig: null,
            }).subscribe(workspace => {
                // this.createFirstWorkflow(workspace);
                const newProfile = {
                    name: workspace.name,
                    type: 'team',
                    id: workspace.id,
                    workspaceDetails: workspace,
                };
                this.availableProfiles.push(newProfile);
                this.setProfile(workspace, true);
            }),
        );
    }

    createFirstWorkflow(workspace) {
        const draftWorkflow: any = {
            'name': 'Default workflow',
            'initialStates': [
                'assigned',
            ],
            'initialUnassignedState': 'assigned',
            'promotionStates': [
                'approved',
            ],
            'openStates': [
                'assigned',
                'for review',
            ],
            'events': [
                {
                    'from': [
                        'assigned',
                    ],
                    'name': 'send to review',
                    'to': 'for review',
                },
                {
                    'from': [
                        'for review',
                    ],
                    'name': 'approve',
                    'to': 'approved',
                },
            ],
            'defaultAssignment': [
                {
                    'state': 'assigned',
                    'user': 'username',
                },
            ],
            'batchOnly': [
                'assigned',
            ],
            'mandatoryCommentEvents': [
            ],
        };
        draftWorkflow.workspaceId = workspace.id ? workspace.id : workspace._id;
        this.subscriptions.add(
            this.workflowService.createDefinition(draftWorkflow).subscribe(data => {
                this.toastrService.success(`Workflow created`, 'Success',
                    { icon: 'checkmark-outline', position: this.position });
            }, error => {
                this.toastrService.danger(`Workflow create error`, 'Error',
                    { position: this.position });
            }),
        );
    }

    loadWorkspaces(redirectToURL?: string) {
        // this.loadUser();
        this.subscriptions.add(
            this.workspaceService.list(this.user.id).subscribe(workspaces => {
                if (workspaces.length === 0) {
                    this.createFirstWorkspace();
                } else {
                    workspaces.forEach(workspace => {
                        const teamProfile: Profile = {
                            name: workspace.name,
                            type: 'team',
                            id: workspace.id,
                            workspaceDetails: workspace,
                        };
                        let included = false;
                        this.availableProfiles.forEach(profile => {
                            if (profile.id === teamProfile.id) {
                                included = true;
                            }
                        });
                        if (included === false) {
                            this.availableProfiles.push(teamProfile);
                        }
                    });
                    this.currentProfile = this.availableProfiles[0];
                    this.route.queryParams.subscribe(params => {
                        if ((!params || Object.keys(params).length === 0 || !params.workspaceId)
                            && (!this.queryParams || !this.queryParams.workspaceId)) {
                            this.appStore.setProfile(this.availableProfiles[0]);
                            this.updateParams();
                            // tslint:disable-next-line: max-line-length
                        } else if (params.workspaceId && this.queryParams && this.queryParams.workspaceId === params.workspaceId) {
                            // console.log('Not updating profile');
                        } else if (params.workspaceId) {
                            this.availableProfiles.forEach(prof => {
                                if (prof.id === params.workspaceId) {
                                    this.setProfile(prof, false);
                                }
                            });
                        }
                    });

                }

            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.appStore.setSetting(themeName)
            .pipe(takeUntil(this.destroy$))
            .subscribe();

        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    compareProfiles(profile1, profile2) {
        return profile1.id === profile2.id;
    }

    createWorkspace() {
        const dialogRef = this.matDialog.open(CreateWorkspaceComponent, {
            width: '60%',
            // data: {
            //     component: StepperComponent, // --> here component to load
            // },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.workspaceService.create(result).subscribe(resWorkspace => {
                    // const type = resWorkspace.userId ? 'user' : 'team';
                    const newProfile = {
                        name: resWorkspace.name,
                        type: 'team',
                        id: resWorkspace.id,
                        workspaceDetails: resWorkspace,
                    };
                    this.availableProfiles.push(newProfile);
                    this.setProfile(newProfile, true);
                    this.queryParams = { workspaceId: resWorkspace.id };
                    this.router.navigate(
                        ['/pages/terminology-resources/summary'],
                        {
                            relativeTo: this.route,
                            queryParams: this.queryParams,
                            replaceUrl: true,
                            // queryParamsHandling: 'merge',
                        },
                    );
                    // this.createFirstWorkflow(resWorkspace);
                });
            } else {
                this.subscriptions.add(
                    this.appStore.getProfile().subscribe(profile => {
                        this.currentProfile = profile;
                    }),
                );
            }
        });
    }
}
