import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Role } from '../../../interfaces/common/roles';

@Injectable()
export class RolesApi {
    private readonly apiController: string = 'roles';

    constructor(private api: HttpService) { }

    //   get rolesDataSource(): DataSource {
    //     return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    //   }

    list(): Observable<any[]> {

        return this.api.get(this.apiController)
            .pipe(map(data => data.map(item => {
                // const picture = `${this.api.apiUrl}/${this.apiController}/${item.id}/photo`;
                return { ...item };
            })));
    }

    //   getCurrent(): Observable<any> {
    //     return this.api.get(`${this.apiController}/current`)
    //       .pipe(map(data => {
    //         const picture = `${this.api.apiUrl}/${this.apiController}/${data.id}/photo`;
    //         return { ...data, picture };
    //       }));
    //   }

    get(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    getByTeam(orgId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/search?teamId=${orgId}`)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    getByUser(userId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/search?userId=${userId}`)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    getByUserAndTeam(userId: string, orgId): Observable<any[]> {
        return this.api.get(`${this.apiController}/search?teamId=${orgId}&userId=${userId}`)
            .pipe(map(data => data.map(item => {
                return { ...item };
            })));
    }

    create(role: Role): Observable<any> {
        return this.api.post(`${this.apiController}`, role);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    updateRole(roleId: string, newRole: Role): Observable<Role> {
        return this.api.put(`${this.apiController}/${roleId}`, newRole);
    }

    //   add(item: any): Observable<any> {
    //     return this.api.post(this.apiController, item);
    //   }

    //   updateCurrent(item: any): Observable<any> {
    //     return this.api.put(`${this.apiController}/current`, item);
    //   }

    //   update(item: any): Observable<any> {
    //     return this.api.put(`${this.apiController}/${item.id}`, item);
    //   }
}
