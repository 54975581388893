import { Component, OnInit, Input, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { WorkflowService } from '../../@core/backend/common/services/workflow.service';
import { WorkflowOptions, WorkflowTask } from '../../@core/interfaces/common/workflow-definition';

@Component({
    selector: 'ngx-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss'],
})

export class WorkflowComponent implements OnInit, OnChanges {
    @Input() taskDisplay: TemplateRef<any>;
    @Input() options: WorkflowOptions;
    @Input() refresh: any;
    private tasks: WorkflowTask<string>[];
    states: {};
    users: {};
    worklists: {};

    constructor(private workflowService: WorkflowService) {
    }

    ngOnInit() {
        // getting definition to use it cached in inner components
        this.loadTasks();

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.refresh) {
            this.loadTasks();
        }
    }

    loadTasks() {
        this.workflowService.getDefinition(this.options.workflowDefintionId).subscribe(() => {
            this.workflowService.getTasks(this.options.endPoint,
                { projectId: this.options.projectId }).subscribe(tasks => {
                    this.tasks = tasks;
                    this.groupTasks();
                });
        });
    }

    updatedTasks(updatedTasks: WorkflowTask<string>[]) {
        this.tasks = this.tasks.map(t => {
            const updatedTask = updatedTasks.find(ut => ut.id === t.id);
            return updatedTask ? updatedTask : t;
        });
        this.groupTasks();
    }

    groupTasks() {
        // grouping tasks by different states, users, worklists
        this.states = {};
        this.users = {};
        this.worklists = {};
        this.tasks.forEach(t => {
            if (typeof this.worklists[t.workList] === 'undefined') {
                this.worklists[t.workList] = [];
            }
            this.worklists[t.workList].push(t);
            if (typeof this.states[t.wfState] === 'undefined') {
                this.states[t.wfState] = [];
            }
            this.states[t.wfState].push(t);
            if (t.assignee) {
                if (typeof this.users[t.assignee.login] === 'undefined') {
                    this.users[t.assignee.login] = [];
                }
                this.users[t.assignee.login].push(t);
            }
        });
    }
}
