
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersApi } from '../api/users.api';
// import { UserData, User } from '../../../interfaces/common/users';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { NbAuthService } from '@nebular/auth';
// import { switchMap, map } from 'rxjs/operators';
import { ProfileData, Profile } from '../../../interfaces/common/profiles';
import { TeamsApi } from '../api/teams.api';
import { Project } from '../../../interfaces/common/projects';
import { ProjectService } from './projects.service';
import { NbAuthService } from '@nebular/auth';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ProfileService extends ProfileData {

    constructor(private usersApi: UsersApi,
        private authService: NbAuthService,
        private teamsApi: TeamsApi,
        private projectService: ProjectService) {
        super();
    }

    getDetails(profile: Profile): Observable<any> {
        if (profile.type === 'user') {
            return this.usersApi.get(profile.id);
        } else if (profile.type === 'team') {
            return this.teamsApi.get(profile.id);
        }
    }

    listProjectsByProfile(profile: Profile): Observable<Project[]> {
        if (profile.type === 'user') {
            return this.projectService.listProjectsByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.projectService.listProjectsByTeam(profile.id);
        }
    }

    listModulesByProfile(profile: Profile): Observable<any[]> {
        if (profile.type === 'user') {
            return this.usersApi.listModulesByUser(profile.id);
        } else if (profile.type === 'team') {
            return this.teamsApi.listModulesByTeam(profile.id);
        }
    }

    //   get gridDataSource(): DataSource {
    //     return this.api.usersDataSource;
    //   }

    //   list(pageNumber: number = 1, pageSize: number = 10): Observable<User[]> {
    //     return this.api.list(pageNumber, pageSize);
    //   }

      getCurrentUser(): Observable<any> {
        return this.authService.isAuthenticated()
          .pipe(
            switchMap(authenticated => {
              return authenticated ? this.usersApi.getCurrent() : of(null);
            }),
            map(u => {
              if (u && !u.setting) {
                u.setting = {};
              }
            return u;
        }));
      }


    //   get(id: string): Observable<User> {
    //     return this.api.get(id);
    //   }

    //   create(user: any): Observable<User> {
    //     return this.api.add(user);
    //   }

    //   update(user: any): Observable<User> {
    //     return this.api.update(user);
    //   }

    //   updateCurrent(user: any): Observable<User> {
    //     return this.api.updateCurrent(user);
    //   }

    //   delete(id: string): Observable<boolean> {
    //     return this.api.delete(id);
    //   }
}
