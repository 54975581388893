import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
// tslint:disable-next-line: max-line-length
import { SubsetRecord, SimpleMapRecord, AdvancedMapRecord, RefsetRecord, ConceptExtensionRecord,
    AuthoringTask, AuthoringRecord, ReviewMessage, SimpleMapTasklist } from '../../../interfaces/common/projects';

@Injectable()
export class ProjectRecordsApi {
    private readonly apiController: string = 'projects';

    constructor(private api: HttpService) { }

    // Subset records API

    listSubsetRecords(projectId: string, active: string): Observable<any[]> {
        let url = `${this.apiController}/subset/records?projectId=${projectId}`;
        if (active) {
            url += `&active=${active}`;
        }
        return this.api.get(url);
    }

    getSubsetRecord(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/subset/records/${id}`);
    }

    addSubsetRecord(item: SubsetRecord): Observable<any> {
        return this.api.post(`${this.apiController}/subset/records`, item);
    }

    // addSubsetRecords(items: SubsetRecord[]): Observable<any> {
    //     return this.api.post(`${this.apiController}/subset/records`, item);
    // }

    updateSubsetRecord(id: string, item: SubsetRecord): Observable<any> {
        return this.api.put(`${this.apiController}/subset/records/${id}`, item);
    }

    deleteSubsetRecord(id: string): any {
        return this.api.delete(`${this.apiController}/subset/records/${id}`);
    }

    addSnowstormResultsToSubset(projectId: string, trId: string,
        term: string, ecl: string): any {
        const request = {
            projectId, trId, term, ecl,
        };
        return this.api.post(`${this.apiController}/subset/records/ecl`, request);
    }

    // Simple Map Records API

    listSimpleMapTasklists(projectId: string, projectKey: string, key: string): Observable<any[]> {
        let url = `${this.apiController}/simplemap/tasklists?1=1`;
        if (projectId) { url += `&projectId=${projectId}`; }
        if (projectKey) { url += `&projectKey=${projectKey}`; }
        if (key) { url += `&key=${key}`; }
        return this.api.get(url);
    }

    getSimpleMapTasklist(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/simplemap/tasklists/${id}`);
    }

    addSimpleMapTasklist(item: SimpleMapTasklist): Observable<any> {
        return this.api.post(`${this.apiController}/simplemap/tasklists`, item);
    }

    updateSimpleMapTasklist(id: string, item: SimpleMapTasklist): Observable<any> {
        return this.api.put(`${this.apiController}/simplemap/tasklists/${id}`, item);
    }

    deleteSimpleMapTasklist(id: string): any {
        return this.api.delete(`${this.apiController}/simplemap/tasklists/${id}`);
    }

    // tslint:disable-next-line: max-line-length
    searchSimpleMapRecords(workspaceId: string, projectId: string, taskListId: string, code: string, text: string,
        workList: string, assignee: string, wfState: string, skip: number, limit: number): Observable<any[]> {
        let url = `${this.apiController}/simplemap/records?1=1`;
        if (workspaceId) { url += `&workspaceId=${workspaceId}`; }
        if (projectId) { url += `&projectId=${projectId}`; }
        if (taskListId) { url += `&taskListId=${taskListId}`; }
        if (code) { url += `&code=${code}`; }
        if (text) { url += `&text=${text}`; }
        if (workList) { url += `&workList=${workList}`; }
        if (assignee) { url += `&assignee=${assignee}`; }
        if (wfState) { url += `&wfState=${wfState}`; }
        if (skip) { url += `&skip=${skip}`; }
        if (limit) { url += `&limit=${limit}`; }

        return this.api.get(url);
    }

    getSimpleMapRecord(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/simplemap/records/${id}`);
    }

    addSimpleMapRecord(item: SimpleMapRecord): Observable<any> {
        return this.api.post(`${this.apiController}/simplemap/records`, item);
    }

    updateSimpleMapRecord(id: string, item: SimpleMapRecord): Observable<any> {
        return this.api.put(`${this.apiController}/simplemap/records/${id}`, item);
    }

    deleteSimpleMapRecord(id: string): any {
        return this.api.delete(`${this.apiController}/simplemap/records/${id}`);
    }

    createSimpleMapAssignmentsFromBriefcase(taskListId: string, assignTo: string, briefcaseUserId: string,
        briefcaseTeamId: string, briefcaseFolder: string, workList: string): Observable<any> {
        let url = `${this.apiController}/simplemap/records/frombriefcase`;
        url += `?taskListId=${taskListId}&assignTo=${assignTo}`;
        if (briefcaseUserId) { url += `&briefcaseUserId=${briefcaseUserId}`; }
        if (briefcaseTeamId) { url += `&briefcaseTeamId=${briefcaseTeamId}`; }
        if (workList) { url += `&workList=${workList}`; }
        url += `&briefcaseFolder=${briefcaseFolder}`;
        return this.api.post(url, {});
    }

    // Advanced Map Records API

    listAdvancedMapRecords(projectId: string): Observable<any[]> {
        return this.api.get(`${this.apiController}/advancedmap/records?projectId=${projectId}`);
    }

    getAdvancedMapRecord(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/advancedmap/records/${id}`);
    }

    addAdvancedMapRecord(item: AdvancedMapRecord): Observable<any> {
        return this.api.post(`${this.apiController}/advancedmap/records`, item);
    }

    updateAdvancedMapRecord(id: string, item: AdvancedMapRecord): Observable<any> {
        return this.api.put(`${this.apiController}/advancedmap/records/${id}`, item);
    }

    deleteAdvancedMapRecord(id: string): any {
        return this.api.delete(`${this.apiController}/advancedmap/records/${id}`);
    }

    createAdvancedMapsAssignmentsFromBriefcase(projectId: string, assignTo: string, briefcaseUserId: string,
        briefcaseTeamId: string, briefcaseFolder: string, workList: string): Observable<any> {
        let url = `${this.apiController}/advancedmap/records/frombriefcase`;
        url += `?projectId=${projectId}&assignTo=${assignTo}`;
        if (briefcaseUserId) { url += `&briefcaseUserId=${briefcaseUserId}`; }
        if (briefcaseTeamId) { url += `&briefcaseTeamId=${briefcaseTeamId}`; }
        if (workList) { url += `&workList=${workList}`; }
        url += `&briefcaseFolder=${briefcaseFolder}`;
        return this.api.post(url, {});
    }

    // Refset records API

    listRefsetRecords(projectId: string, active: string, commit): Observable<any> {
        let url = `${this.apiController}/refset/records?projectId=${projectId}`;
        if (active) {
            url += `&active=${active}`;
        }
        if (commit) {
            url += `&commitTimestamp=${commit.timestamp}&commitMessage=${commit.message}`;
            if (commit.ecl) {
                url += `&commitEcl=${commit.ecl}`;
            }
        }
        return this.api.get(url);
    }

    getRefsetRecord(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/refset/records/${id}`);
    }

    addRefsetRecord(item: RefsetRecord): Observable<any> {
        return this.api.post(`${this.apiController}/refset/records`, item);
    }

    addRefsetRecords(items: RefsetRecord[]): Observable<any> {
        return this.api.post(`${this.apiController}/refset/records/bulk`, items);
    }

    updateRefsetRecord(id: string, item: RefsetRecord): Observable<any> {
        return this.api.put(`${this.apiController}/refset/records/${id}`, item);
    }

    inactivateRefsetRecordByCodeId(codeId: string, projectId: string): Observable<any> {
        return this.api.put(`${this.apiController}/refset/records/inactivateRefsetRecordByCodeId/${codeId}`,
            { projectId: projectId });
    }

    deleteRefsetRecord(id: string): any {
        return this.api.delete(`${this.apiController}/refset/records/${id}`);
    }

    createRefsetRecordsFromBriefcase(workspaceId: string, projectId: string, assignTo: string, briefcaseUserId: string,
        briefcaseTeamId: string, briefcaseFolder: string, workList: string): Observable<any> {
        let url = `${this.apiController}/refset/records/frombriefcase?assignTo=${assignTo}`;
        if (projectId) { url += `&projectId=${projectId}`; }
        if (workspaceId) { url += `&workspaceId=${workspaceId}`; }
        if (briefcaseUserId) { url += `&briefcaseUserId=${briefcaseUserId}`; }
        if (briefcaseTeamId) { url += `&briefcaseTeamId=${briefcaseTeamId}`; }
        if (workList) { url += `&workList=${workList}`; }
        url += `&briefcaseFolder=${briefcaseFolder}`;
        return this.api.post(url, {});
    }

    syncWithQuery(projectId: string, trId: string,
        term: string, ecl: string): any {
        const request = {
            projectId, trId, term, ecl,
        };
        return this.api.post(`${this.apiController}/refset/records/query/sync`, request);
    }

    addSnowstormResultsToRefset(projectId: string, trId: string,
        term: string, ecl: string): any {
        const request = {
            projectId, trId, term, ecl,
        };
        return this.api.post(`${this.apiController}/refset/records/ecl`, request);
    }

    removeSnowstormResultsFromRefset(projectId: string, trId: string,
        term: string, ecl: string): any {
        const request = {
            projectId, trId, term, ecl,
        };
        return this.api.put(`${this.apiController}/refset/records/ecl`, request);
    }

    getCommitSummary(projectId: string): any {
        return this.api.get(`${this.apiController}/refset/records/commitSummary?projectId=${projectId}`);
    }

    bulkUpdate(records: RefsetRecord[], projectId): any {
        return this.api.post(`${this.apiController}/refset/records/updateBulk?projectId=${projectId}`, records);
    }

    uploadToRefset(filename: string, spec: any, projectId: string) {
        const body = {
            projectId,
            spec,
            filename,
        };
        return this.api.post(`${this.apiController}/refset/records/upload`, body);
    }

    // Concept Extension records API

    listConceptExtensionRecords(projectId: string, text: string, active: string): Observable<any[]> {
        let url = `${this.apiController}/conceptextension/records?projectId=${projectId}`;
        if (text) {
            url += `&text=${text}`;
        }
        if (active) {
            url += `&active=${active}`;
        }
        return this.api.get(url);
    }

    getConceptExtensionRecord(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/conceptextension/records/${id}`);
    }

    addConceptExtensionRecord(item: ConceptExtensionRecord): Observable<any> {
        return this.api.post(`${this.apiController}/conceptextension/records`, item);
    }

    addConceptExtensionRecords(items: ConceptExtensionRecord[]): Observable<any> {
        return this.api.post(`${this.apiController}c/onceptextension/records/bulk`, items);
    }

    updateConceptExtensionRecord(id: string, item: ConceptExtensionRecord): Observable<any> {
        return this.api.put(`${this.apiController}/conceptextension/records/${id}`, item);
    }

    deleteConceptExtensionRecord(id: string): any {
        return this.api.delete(`${this.apiController}/conceptextension/records/${id}`);
    }

    // Authoring project tasks API

    listAuthoringProjectTasks(projectId: string, key?: number, projectKey?: string): Observable<any[]> {
        let url = `${this.apiController}/authoringProject/tasks?projectId=${projectId}`;
        if (key) {
            url += `&key=${key}`;
        }
        if (projectKey) {
            url += `&projectKey=${projectKey}`;
        }
        return this.api.get(url);
    }

    getAuthoringProjectTask(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/authoringProject/tasks/${id}`);
    }

    addAuthoringProjectTask(item: AuthoringTask): Observable<any> {
        return this.api.post(`${this.apiController}/authoringProject/tasks`, item);
    }

    updateAuthoringProjectTask(id: string, item: AuthoringTask): Observable<any> {
        return this.api.put(`${this.apiController}/authoringProject/tasks/${id}`, item);
    }

    listAuthoringProjectRecords(projectId: string, taskId?: string, assignee?: string,
        workList?: string, text?: string, active?: boolean): Observable<any[]> {
        let url = `${this.apiController}/authoringProject/records?projectId=${projectId}`;
        if (taskId) {
            url += `&taskId=${taskId}`;
        }
        if (assignee) {
            url += `&assignee=${assignee}`;
        }
        if (workList) {
            url += `&workList=${workList}`;
        }
        if (text) {
            url += `&text=${text}`;
        }
        if (active) {
            url += `&active=${active}`;
        }
        return this.api.get(url);
    }

    getAuthoringProjectRecord(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/authoringProject/records/${id}`);
    }

    addAuthoringProjectRecord(item: AuthoringRecord): Observable<any> {
        return this.api.post(`${this.apiController}/authoringProject/records`, item);
    }

    updateAuthoringProjectRecord(id: string, item: AuthoringRecord): Observable<any> {
        return this.api.put(`${this.apiController}/authoringProject/records/${id}`, item);
    }
    // Review Messages / Feedback API
    listAuthoringProjectTaskMessages(taskId: string, conceptId: string): Observable<any[]> {
        const url = `${this.apiController}/authoringProject/tasksReview?taskId=${taskId}&conceptId=${conceptId}`;
        return this.api.get(url);
    }

    getAuthoringProjectTaskMessage(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/authoringProject/tasksReview/${id}`);
    }

    addAuthoringProjectTaskMessage(item: ReviewMessage): Observable<any> {
        return this.api.post(`${this.apiController}/authoringProject/tasksReview`, item);
    }

    updateAuthoringProjectTaskMessage(id: string, item: ReviewMessage): Observable<any> {
        return this.api.put(`${this.apiController}/authoringProject/tasksReview/${id}`, item);
    }
}
