import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProjectRole, ProjectRoleData } from '../../../interfaces/common/project-roles';
import { ProjectRolesApi } from '../api/project-roles.api';

@Injectable()
export class ProjectRoleService extends ProjectRoleData {

    public rolesUpdated = new BehaviorSubject<boolean>(false);
    public rolesUpdatedEvent = this.rolesUpdated.asObservable();

    constructor(private api: ProjectRolesApi) {
        super();
    }

    update() {
        this.rolesUpdated.next(true);
        this.rolesUpdated.next(false);
    }

    list(): Observable<ProjectRole[]> {
        return this.api.list();
    }


    get(id: string): Observable<ProjectRole> {
        return this.api.get(id);
    }

    getByProject(projectId: string): Observable<ProjectRole[]> {
        return this.api.getByProject(projectId);
    }

    getByUser(userId: string): Observable<ProjectRole[]> {
        return this.api.getByUser(userId);
    }

    getByUserAndProject(userId: string, projectId: string): Observable<ProjectRole[]> {
        return this.api.getByUserAndProject(userId, projectId);
    }

    create(role: any): Observable<ProjectRole> {
        return this.api.create(role);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(id);
    }

    updateRole(roleId: string, newRole: ProjectRole): Observable<ProjectRole> {
        return this.api.updateRole(roleId, newRole);
    }
}
