import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TxtImportApi } from '../api/txtimport.api';

@Injectable()
export class TxtImportService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: TxtImportApi) {
    }

    preview(file: any): Observable<any[]> {
        return this.api.preview(file);
    }

    extractPost(file: any, columns: string[]): Observable<any[]> {
        return this.api.extractPost(file, columns);
    }

    extractGet(fileName: string, columns: string[]): Observable<any[]> {
        return this.api.extractGet(fileName, columns);
    }
}
