import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MergeApi } from '../api/merge.api';

@Injectable()
export class MergeService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: MergeApi) {
    }

    getSimpleMapTaskReviews(projectId: string, taskListId: string, status: string): Observable<any[]> {
        return this.api.getSimpleMapTaskReviews(projectId, taskListId, status);
    }

    getRefsetWorkspaceReviews(projectId: string, workspaceId: string, status: string): Observable<any[]> {
        return this.api.getRefsetWorkspaceReviews(projectId, workspaceId, status);
    }

    getRefsetWorkspaceReview(workspaceReviewId: string): Observable<any> {
        return this.api.getRefsetWorkspaceReview(workspaceReviewId);
    }

    createRefsetWorkspaceReviews(projectId: string, workspaceId: string): Observable<any[]> {
        return this.api.createRefsetWorkspaceReviews(projectId, workspaceId);
    }

    rebaseRefset(projectId: string, workspaceId: string, conflictResolutionStrategy: string): Observable<any[]> {
        return this.api.rebaseRefset(projectId, workspaceId, conflictResolutionStrategy);
    }

    promoteRefset(projectId: string, workspaceId: string): Observable<any[]> {
        return this.api.promoteRefset(projectId, workspaceId);
    }

    getProjectStatus(projectId: string): Observable<any> {
        return this.api.getProjectStatus(projectId);
    }
}
