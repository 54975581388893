import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class BlueprintsApi {
    private readonly controller: string = 'blueprints';
    constructor(private api: HttpService) { }

    getTrBlueprints(): Observable<any[]> {
        return this.api.get(this.controller + '/terminologyResources');
    }

    getWfBlueprints(): Observable<any[]> {
        return this.api.get(this.controller + '/workflowDefinitions');
    }

    getPatterns(): Observable<any[]> {
        return this.api.get(this.controller + '/refsetPatterns');
    }
}
