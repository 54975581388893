import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
// tslint:disable-next-line: max-line-length
import { SnowstormTerminologyResource, CodesystemTerminologyResource, TermspaceTerminologyResource, IcdApiTerminologyResource, FhirTerminologyResource, VsacTerminologyResource, UmlsTerminologyResource } from '../../../interfaces/common/terminology-resources';
// import { map } from 'rxjs/operators';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { Profile } from '../../../interfaces/common/profiles';

@Injectable()
export class TerminologyResourcesApi {
    private readonly snowstormtrApiController: string = 'snomedtr';
    private readonly termspacetrApiController: string = 'termspacetr';
    private readonly codesystemtrApiController: string = 'codesystemtr';
    private readonly codeSystemCodesApiController: string = 'code';
    private readonly icdapitrApiController: string = 'icdapitr';
    private readonly fhirtrController: string = 'fhircstr';
    private readonly vsactrController: string = 'vsactr';
    private readonly umlstrController: string = 'umlstr';
    constructor(private api: HttpService) { }

    //   get terminologyresourcesDataSource(): DataSource {
    //     return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    //   }

    //   list(): Observable<any[]> {

    //     return this.api.get(this.apiController)
    //       .pipe(map(data => data.map(item => {
    //         // const picture = `${this.api.apiUrl}/${this.apiController}/${item.id}/photo`;
    //         return { ...item};
    //       })));
    //   }

    createSnowstormTerminologyResource(str: SnowstormTerminologyResource) {
        return this.api.post(this.snowstormtrApiController, str);
        //     .pipe(map(data => data.map(item => {
        //         return {...item};
        // })));
    }

    updateSnowstormTerminologyResource(str: SnowstormTerminologyResource) {
        return this.api.put(this.snowstormtrApiController + '/' + str.id, str);
        //     .pipe(map(data => data.map(item => {
        //         return {...item};
        // })));
    }

    deleteSnowstormTerminologyResource(str: SnowstormTerminologyResource): Observable<boolean> {
        return this.api.delete(this.snowstormtrApiController + '/' + str);
    }

    createCodesystemTerminologyResource(cstr: CodesystemTerminologyResource) {
        return this.api.post(this.codesystemtrApiController, cstr);
        //     .pipe(map(data => data.map(item => {
        //         return {...item};
        // })));
    }

    updateCodesystemTerminologyResource(cstr: CodesystemTerminologyResource) {
        return this.api.put(this.codesystemtrApiController + '/' + cstr.id, cstr);
        //     .pipe(map(data => data.map(item => {
        //         return {...item};
        // })));
    }

    deleteCodesystemTerminologyResource(cstr: CodesystemTerminologyResource): Observable<boolean> {
        return this.api.delete(this.codesystemtrApiController + '/' + cstr);
    }


    createTermspaceTerminologyResource(tstr: TermspaceTerminologyResource) {
        return this.api.post(this.termspacetrApiController, tstr);
        //     .pipe(map(data => data.map(item => {
        //         return {...item};
        // })));
    }

    updateTermspaceTerminologyResource(tstr: TermspaceTerminologyResource) {
        return this.api.put(this.termspacetrApiController + '/' + tstr.id, tstr);
        //     .pipe(map(data => data.map(item => {
        //         return {...item};
        // })));
    }

    deleteTermspaceTerminologyResource(tstr: TermspaceTerminologyResource): Observable<boolean> {
        return this.api.delete(this.termspacetrApiController + '/' + tstr);
    }

    createIcdApiTerminologyResource(icdtr: IcdApiTerminologyResource) {
        return this.api.post(this.icdapitrApiController, icdtr);
    }

    updateIcdApiTerminologyResource(icdtr: IcdApiTerminologyResource) {
        return this.api.put(this.icdapitrApiController + '/' + icdtr.id, icdtr);
    }

    deleteICDApiTerminologyResource(icdtr: IcdApiTerminologyResource): Observable<boolean> {
        return this.api.delete(this.icdapitrApiController + '/' + icdtr);
    }

    createFhirTerminologyResource(fhirtr: FhirTerminologyResource) {
        return this.api.post(this.fhirtrController, fhirtr);
    }

    updateFhirTerminologyResource(fhirtr: FhirTerminologyResource) {
        return this.api.put(this.fhirtrController + '/' + fhirtr.id, fhirtr);
    }

    deleteFhirTerminologyResource(fhirtr: FhirTerminologyResource): Observable<boolean> {
        return this.api.delete(this.fhirtrController + '/' + fhirtr);
    }

    createVsacTerminologyResource(vsactr: VsacTerminologyResource) {
        return this.api.post(this.vsactrController, vsactr);
    }

    updateVsacTerminologyResource(vsactr: VsacTerminologyResource) {
        return this.api.put(this.vsactrController + '/' + vsactr.id, vsactr);
    }

    deleteVsacTerminologyResource(vsactr: VsacTerminologyResource): Observable<boolean> {
        return this.api.delete(this.vsactrController + '/' + vsactr);
    }

    createUmlsTerminologyResource(umlstr: UmlsTerminologyResource) {
        return this.api.post(this.umlstrController, umlstr);
    }

    updateUmlsTerminologyResource(umlstr: UmlsTerminologyResource) {
        return this.api.put(this.umlstrController + '/' + umlstr.id, umlstr);
    }

    deleteUmlsTerminologyResource(umlstr: UmlsTerminologyResource): Observable<boolean> {
        return this.api.delete(this.umlstrController + '/' + umlstr);
    }

    getSnowstormTerminologyResourcesByUser(userId: String): Observable<SnowstormTerminologyResource[]> {
        return this.api.get(this.snowstormtrApiController + '?userId=' + userId);
    }

    getSnowstormTerminologyResourcesByTeam(orgId: String): Observable<SnowstormTerminologyResource[]> {
        return this.api.get(this.snowstormtrApiController + '?teamId=' + orgId);
    }

    getCodesystemTerminologyResourcesByUser(userId: String): Observable<CodesystemTerminologyResource[]> {
        return this.api.get(this.codesystemtrApiController + '?userId=' + userId);
    }

    getCodesystemTerminologyResourcesByTeam(orgId: String): Observable<CodesystemTerminologyResource[]> {
        return this.api.get(this.codesystemtrApiController + '?teamId=' + orgId);
    }

    getTermspaceTerminologyResourcesByUser(userId: String): Observable<TermspaceTerminologyResource[]> {
        return this.api.get(this.termspacetrApiController + '?userId=' + userId);
    }

    getTermspaceTerminologyResourcesByTeam(orgId: String): Observable<TermspaceTerminologyResource[]> {
        return this.api.get(this.termspacetrApiController + '?teamId=' + orgId);
    }

    getIcdApiTerminologyResourcesByUser(userId: String): Observable<IcdApiTerminologyResource[]> {
        return this.api.get(this.icdapitrApiController + '?userId=' + userId);
    }

    getIcdApiTerminologyResourcesByTeam(orgId: String): Observable<IcdApiTerminologyResource[]> {
        return this.api.get(this.icdapitrApiController + '?teamId=' + orgId);
    }

    getFhirTerminologyResourcesByUser(userId: String): Observable<FhirTerminologyResource[]> {
        return this.api.get(this.fhirtrController + '?userId=' + userId);
    }

    getFhirTerminologyResourcesByTeam(orgId: String): Observable<FhirTerminologyResource[]> {
        return this.api.get(this.fhirtrController + '?teamId=' + orgId);
    }

    getVsacTerminologyResourcesByUser(userId: String): Observable<VsacTerminologyResource[]> {
        return this.api.get(this.vsactrController + '?userId=' + userId);
    }

    getVsacTerminologyResourcesByTeam(orgId: String): Observable<VsacTerminologyResource[]> {
        return this.api.get(this.vsactrController + '?teamId=' + orgId);
    }

    getUmlsTerminologyResourcesByUser(userId: String): Observable<UmlsTerminologyResource[]> {
        return this.api.get(this.umlstrController + '?userId=' + userId);
    }

    getUmlsTerminologyResourcesByTeam(orgId: String): Observable<UmlsTerminologyResource[]> {
        return this.api.get(this.umlstrController + '?teamId=' + orgId);
    }

    getCodeSystemCodes(id: String): Observable<any[]> {
        return this.api.get(this.codeSystemCodesApiController + '?codeSystemId=' + id);
    }

    searchCodeSystemCodes(id: String, term: string, skip: string, limit: string): Observable<any[]> {
        let url = this.codeSystemCodesApiController + '?codeSystemId=' + id;
        if (term) {
            url += `&term=${term}`;
        }
        if (skip) {
            url += `&skip=${skip}`;
        }
        if (limit) {
            url += `&limit=${limit}`;
        }
        return this.api.get(url);
    }

    uploadCodes(id: string, file: any): Observable<any> {
        return this.api.post(this.codeSystemCodesApiController + '/upload/' + id, file);
    }

    importCodesFromFile(id: string, filename: any, spec: any): Observable<any> {
        return this.api.post(this.codeSystemCodesApiController + '/import/' + id, {filename, spec});
    }

    copyCodes(sourceCodesystemId: string, targetCodesystemId: string) {
        return this.api.post(this.codeSystemCodesApiController
            + '/copyCodes?targetCodesystemId=' + targetCodesystemId + '&&sourceCodesystemId='
                + sourceCodesystemId, null);
    }

    getCodeSystemInfo(id: string): Observable<any> {
        return this.api.get(this.codesystemtrApiController + '/' + id);
    }

    addCode(code): Observable<any> {
        return this.api.post(this.codeSystemCodesApiController, code);
    }

    getCode(id): Observable<any> {
        return this.api.get(this.codeSystemCodesApiController + '/' + id);
    }

    updateCode(code): Observable<any> {
        return this.api.put(this.codeSystemCodesApiController + '/' + code.id, code);
    }

    getTermSpaceInfo(id: string): Observable<any> {
        return this.api.get(this.termspacetrApiController + '/' + id);
    }

    getSnowstormInfo(id: string): Observable<any> {
        return this.api.get(this.snowstormtrApiController + '/' + id);
    }

    getFhirInfo(id: string): Observable<any> {
        return this.api.get(this.fhirtrController + '/' + id);
    }

    getVsacInfo(id: string): Observable<any> {
        return this.api.get(this.vsactrController + '/' + id);
    }

    getUmlsInfo(id: string): Observable<any> {
        return this.api.get(this.umlstrController + '/' + id);
    }

    getIcdInfo(id: string): Observable<any> {
        return this.api.get(this.icdapitrApiController + '/' + id);
    }
}
