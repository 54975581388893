import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class QueryApi {
    private readonly apiController: string = 'query';
    constructor(private api: HttpService) { }

    getQueries(workspaceId: string, status: string): Observable<any[]> {
        let url = `${this.apiController}?`;
        if (workspaceId) {
            url = url + `workspaceId=${workspaceId}&`;
        }
        if (status) {
            url = url + `active=${status}&`;
        }
        return this.api.get(url);
    }

    createQuery(query: any): Observable<any> {
        const url = `${this.apiController}?`;
        return this.api.post(url, query);
    }

    updateQuery(query: any): Observable<any> {
        const url = `${this.apiController}/${query.id}?`;
        return this.api.put(url, query);
    }

    deleteQuery(id: string): Observable<any> {
        const url = `${this.apiController}/${id}?`;
        return this.api.delete(url);
    }

    getQuery(id: string): Observable<any> {
        const url = `${this.apiController}/${id}`;
        return this.api.get(url);
    }
}
