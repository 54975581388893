import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IcdApi } from '../api/icd.api';

@Injectable()
export class IcdService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: IcdApi) {
    }

    getVersions(icdTrId: string): Observable<any> {
        return this.api.getVersions(icdTrId);
    }

    getRoot(icdTrId: string, version: string): Observable<any> {
        return this.api.getRoot(icdTrId, version);
    }

    getCode(icdTrId: string, version: string, code: string): Observable<any> {
        return this.api.getCode(icdTrId, version, code);
    }

    getChildren(icdTrId: string, version: string, code: string): Observable<any> {
        const result = new Observable<any>(observer => {
            this.api.getCode(icdTrId, version, code).subscribe(data => {
                data['child'].forEach(element => {
                    const loopCode = element.match(/[^\/]+$/)[0];
                    this.getCode(icdTrId, version, loopCode).subscribe(loopData => {
                        observer.next(loopData);
                    });
                });
            });
        });
        return result;
    }

    addChildren(obs: Observable<any>): Observable<any> {
        const result = new Observable<any>(observer => {
            obs.subscribe(data => {
                data['child'].forEach(element => {
                    const loopChild = { title: {} };
                    loopChild['title']['@value'] = element;
                    observer.next(loopChild);
                });
            });
        });
        return result;
    }

}
