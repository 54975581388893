import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthApi } from '../api/auth.api';

@Injectable()
export class AuthApiService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: AuthApi) {
    }

    loginGoogleUser(googleUser: any): Observable<any> {
        return this.api.loginGoogleUser(googleUser);
    }
}
