import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WorkflowApi } from '../api/workflow.api';
import {
    WorkflowDefinition,
    WorkflowTask,
    WorkflowTransition,
    WorkflowTaskFilter,
    WorkflowSummary,
    WorkflowTreeNode,
    WorkflowTreeIcon,
} from '../../../interfaces/common/workflow-definition';
import { filter, switchMap } from 'rxjs/operators';

@Injectable()
export class WorkflowService {

    private cachedDefinitions = {};
    // public workflowUpdated = new BehaviorSubject<boolean>(false);
    // public workflowUpdatedEvent = this.workflowUpdated.asObservable();

    private treeIcons: { [key: string]: WorkflowTreeIcon; } = {
        _states: {
            closed: 'add',
            open: 'remove',
            label: 'play_circle_outline',
        },
        _batchs: {
            closed: 'add',
            open: 'remove',
            label: 'folder_open',
        },
    };
    private validFields = ['_states', '_batchs'];
    private filterMap = { '_states': 'wfState', '_batchs': 'workList' };

    constructor(private api: WorkflowApi) { }

    matchFilter(t: WorkflowTask<string>, filterO: WorkflowTaskFilter): boolean {
        return (!filterO.text || t.display.toLocaleLowerCase().includes(filterO.text.toLocaleLowerCase())) &&
            (!filterO.worklist || t.workList === filterO.worklist) &&
            (!filterO.state || t.wfState === filterO.state) &&
            (!filterO.user || t.assignee.login === filterO.user);
    }

    getTransitions(wd: WorkflowDefinition, states: string | string[]): WorkflowTransition[] {
        let result;
        if (typeof states === 'string') {
            result = wd.events.filter(e => e.from.includes(states));
        } else {
            result = wd.events.filter(e => states.filter(s => e.from.includes(s)).length === states.length);
        }
        return result;
    }

    getDefinitions(profile: any): Observable<WorkflowDefinition[]> {
        return this.api.getDefinitions(profile).pipe(filter(wds => {
            wds.forEach(wd => this.cachedDefinitions[wd.id] = wd);
            return true;
        }));
    }
    // getDefinitions(): Observable<WorkflowDefinition[]> {
    //     return this.api.getDefinitions();
    // }

    getDefinition(id: string): Observable<WorkflowDefinition> {
        if (this.cachedDefinitions[id]) {
            return of(this.cachedDefinitions[id]);
        } else {
            return this.api.getDefinition(id).pipe(filter(wd => {
                this.cachedDefinitions[id] = wd;
                return true;
            }));
        }
    }

    createDefinition(wd: WorkflowDefinition): Observable<WorkflowDefinition> {
        return this.api.createDefinition(wd);
    }

    updateDefinition(id: string, wd: WorkflowDefinition): Observable<WorkflowDefinition> {
        return this.api.updateDefinition(id, wd);
    }

    deleteDefinition(id: string): Observable<string> {
        return this.api.deleteDefinition(id);
    }

    replaceSpaces(name) {
        name = name.replace(new RegExp('/', 'g'), '_');
        return name.replace(new RegExp(' ', 'g'), '_');
    }

    getStates(workflowDefinition) {
        const states = [];
        workflowDefinition.events.forEach(function (event) {
            if (states.indexOf(event.to) === -1) {
                states.push(event.to);
            }
            if (Object.prototype.toString.call(event.from) === '[object Array]') {
                event.from.forEach(function (from) {
                    if (states.indexOf(from) === -1) {
                        states.push(from);
                    }
                });
            } else {
                if (states.indexOf(event.from) === -1) {
                    states.push(event.from);
                }
            }
        });
        return states;
    }

    convertToGraph(workflowDefinition, parameters) {
        let dotNotation = 'digraph workflow { \n';
        if (!parameters.widthInInches) parameters.widthInInches = '15';
        if (!parameters.heigthInInches) parameters.heigthInInches = '18';
        if (!parameters.fontSize) parameters.fontSize = '9';
        dotNotation += 'size ="' + parameters.widthInInches + ',' + parameters.heigthInInches + '";\n';
        dotNotation += 'node[fontsize=' + parameters.fontSize;
        // dotNotation += ',URL = "javascript:void(0);"';
        dotNotation += ',shape=plaintext,fontname = "Open Sans"];\n';
        dotNotation += 'rankdir=TD;\n';
        dotNotation += 'edge [arrowhead=onormal, fontsize=' + parameters.fontSize + ' fontname = "Open Sans"];\n';

        const states = this.getStates(workflowDefinition);
        states.forEach(function (state) {
            let borderColor = workflowDefinition.openStates.indexOf(state) > -1 ? 'green' : 'red';
            let fillColor = 'white';
            if (workflowDefinition.initialStates.indexOf(state) > -1) {
                borderColor = 'blue';
            }
            if (workflowDefinition.promotionStates.indexOf(state) > -1) {
                fillColor = 'darkseagreen';
            }
            let name = state.replace(new RegExp('/', 'g'), '_');
            name = name.replace(new RegExp(' ', 'g'), '_');
            dotNotation += 'node[shape="box", color="' + borderColor + '", fillcolor="' +
                fillColor + '", style="rounded, filled"';
            dotNotation += ',URL = "javascript:void(0);", label="' + state + '"';
            dotNotation += '] ' + name + ';\n';
        });

        workflowDefinition.events.forEach(function (event) {
            if (Object.prototype.toString.call(event.from) === '[object Array]') {
                event.from.forEach(function (from) {
                    const nameFrom = from.replace(new RegExp('/', 'g'), '_').replace(new RegExp(' ', 'g'), '_');
                    const nameTo = event.to.replace(new RegExp('/', 'g'), '_').replace(new RegExp(' ', 'g'), '_');
                    dotNotation += nameFrom + ' -> ' + nameTo + ' [label="' + event.name + '"';
                    dotNotation += ',URL = "javascript:void(0);"];\n';
                });
            } else {
                const nameFrom = event.from.replace(new RegExp('/', 'g'), '_').replace(new RegExp(' ', 'g'), '_');
                const nameTo = event.to.replace(new RegExp('/', 'g'), '_').replace(new RegExp(' ', 'g'), '_');
                const name = event.name.replace(new RegExp('/', 'g'), '_').replace(new RegExp(' ', 'g'), '_');
                dotNotation += nameFrom + ' -> ' + nameTo + ' [label="' + name + '"';
                dotNotation += ',URL = "javascript:void(0);"];\n';
            }
        });
        dotNotation += '}';
        return dotNotation;
    }

    // Tasks Services

    createTasks(endPoint: string, tasks: WorkflowTask<string>[]): Observable<WorkflowTask<string>[]> {
        return this.api.createTasks(endPoint, tasks);
    }

    private processSummaryIntoTreeNodes(ts: WorkflowSummary): WorkflowTreeNode[] {
        const newTree: WorkflowTreeNode = { text: 'Root', children: [] };
        const processSummary = (node: WorkflowTreeNode, s: WorkflowSummary, filters: object, filterField?: string) => {
            const keys = Object.keys(s);
            keys.forEach(key => {
                const value = s[key];
                const icons: WorkflowTreeIcon = filterField ? this.treeIcons[filterField] : this.treeIcons[key];
                const validFilterField = key !== '_total' && filterField && this.validFields.includes(filterField);
                const nodeFilters = validFilterField ? { ...filters, [this.filterMap[filterField]]: key } : filters;
                if (typeof value === 'number') {
                    node.children.push({
                        // text: `${key.replace('_total', filterField.replace('_', 'All '))} (${value})`,
                        text: key.replace('_total', filterField.replace('_', 'All ')),
                        count: value, filters: nodeFilters, icons, children: [],
                    });
                } else {
                    node.children.push({ text: key, children: [], icons });
                    processSummary(node.children[node.children.length - 1], value, { ...nodeFilters }, key);
                }
            });
        };
        const formatNode = (node: WorkflowTreeNode) => {
            if (node.children && node.children.length) {
                const unneeded = node.children.length === 1 && this.validFields.includes(node.children[0].text);
                if (unneeded) {
                    node.children = node.children[0].children;
                } else {
                    node.children.forEach(nodeC => {
                        if (this.validFields.includes(nodeC.text)) {
                            nodeC.text = nodeC.text.replace('_', '');
                        }
                        formatNode(nodeC);
                    });
                }
            }
        };
        processSummary(newTree, ts, {});
        formatNode(newTree);
        return newTree.children;
    }

    getTasksSummary(endPoint: string, query?: object, groupFields?: string[]): Observable<any> {
        return this.api.getTasksSummary(endPoint, query, groupFields).pipe(switchMap(ts => {
            const newObj = {
                _states: {},
                _batchs: {},
                _assignees: {},
            };
            let states = [], wlists = [], _assignees = [];
            ts.forEach(t => {
                const { workList, wfState, assignee } = t._id;
                // const { workList, wfState } = t._id;
                states.push(wfState);
                wlists.push(workList);
                _assignees.push(assignee[0]);
                // const assigneeVS: string = (assignee && assignee.length && assignee[0].login) || 'unassigned';
                if (typeof newObj._batchs[workList] === 'undefined') {
                    newObj._batchs[workList] = { _states: { _total: 0 } };
                }
                if (typeof newObj._batchs[workList]._states[wfState] === 'undefined') {
                    newObj._batchs[workList]._states[wfState] = 0;
                }
                newObj._batchs[workList]._states._total += t.count;
                newObj._batchs[workList]._states[wfState] += t.count;
                if (typeof newObj._states[wfState] === 'undefined') {
                    newObj._states[wfState] = { _batchs: { _total: 0 } };
                }
                if (typeof newObj._states[wfState]._batchs[workList] === 'undefined') {
                    newObj._states[wfState]._batchs[workList] = 0;
                }
                newObj._states[wfState]._batchs._total += t.count;
                newObj._states[wfState]._batchs[workList] += t.count;
            });
            const distinctF = (e, i, arr) => arr.findIndex(eL => eL === e) === i;
            states = states.filter(distinctF);
            wlists = wlists.filter(distinctF);
            _assignees = this.getUnique(_assignees, '_id');
            // Dinammically checking if there are redundant tasks loaders. eg: unassigned (3) and total(3)
            Object.keys(newObj).forEach(o => {
                Object.keys(newObj[o]).forEach(o2 => {
                    Object.keys(newObj[o][o2]).forEach(key => {
                        if (Object.keys(newObj[o][o2][key]).length === 2) {
                            delete newObj[o][o2][key]._total;
                        }
                    });
                });
            });
            return of({
                nodes: this.processSummaryIntoTreeNodes(newObj),
                filters: { states, wlists, _assignees },
            });
        }));
    }

    getUnique(arr, comp) {
        // store the comparison  values in array
        const unique = arr.map(e => e[comp])
            // store the indexes of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the false indexes & return unique objects
            .filter((e) => arr[e]).map(e => arr[e]);
        return unique;
    }


    // getTasks(endPoint: string, projectId: string): Observable<WorkflowTask<string>[]> {
    getTasks(endPoint: string, filters: object): Observable<WorkflowTask<string>[]> {
        return this.api.getTasks(endPoint, filters);
    }

    updateTasks(endPoint: string, taskIds: string[], update: object) {
        return this.api.updateTasks(endPoint, taskIds, update);
    }

    deleteTasks(endPoint: string) {
        return this.api.deleteTasks(endPoint);
    }

    createTask(endPoint: string, task: WorkflowTask<string>): Observable<WorkflowTask<string>[]> {
        return this.api.createTask(endPoint, task);
    }

    getTask(endPoint: string, taskId: string): Observable<WorkflowTask<string>> {
        return this.api.getTask(endPoint, taskId);
    }

    updateTask(endPoint: string, task: WorkflowTask<string>) {
        return this.api.updateTask(endPoint, task);
    }

    deleteTask(endPoint: string, taskId: string) {
        return this.api.deleteTask(endPoint, taskId);
    }
}
