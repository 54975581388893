import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
// import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class UmlsApi {
    private readonly apiController: string = 'umlsapi';
    constructor(private api: HttpService) { }

    getSabs(): Observable<any> {
        const url = `${this.apiController}/sabs`;
        return this.api.get(url);
    }

    search(trId: string, searchTerm: string, pageNumber?: number, pageSize?: number): Observable<any> {
        let url = `${this.apiController}/${trId}/search?a=1`;
        if (searchTerm) {
            url = url + `&searchTerm=${searchTerm}`;
        }
        if (pageNumber || pageNumber === 0) {
            url = url + `&pageNumber=${pageNumber}`;
        }
        if (pageSize || pageSize === 0) {
            url = url + `&pageSize=${pageSize}`;
        }
        return this.api.get(url);
    }

    getCuiConcept(trId: string, cui: string, version: string): Observable<any> {
        const url = `${this.apiController}/${trId}/content/${version}/CUI/${cui}`;
        return this.api.get(url);
    }

    getCuiAtoms(trId: string, uri: string): Observable<any> {
        const url = `${this.apiController}/${trId}/cui/atoms?uri=${uri}`;
        return this.api.get(url);
    }

    getSourceAtoms(trId: string, uri: string): Observable<any> {
        const url = `${this.apiController}/${trId}/source/atoms?uri=${uri}`;
        return this.api.get(url);
    }

    getSourceParents(trId: string, uri: string): Observable<any> {
        const url = `${this.apiController}/${trId}/source/parents?uri=${uri}`;
        return this.api.get(url);
    }

    getSourceChildren(trId: string, uri: string): Observable<any> {
        const url = `${this.apiController}/${trId}/source/children?uri=${uri}`;
        return this.api.get(url);
    }

    getSourceAttributes(trId: string, uri: string): Observable<any> {
        const url = `${this.apiController}/${trId}/source/attributes?uri=${uri}`;
        return this.api.get(url);
    }

}
