import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { JobsApi } from '../api/jobs.api';

@Injectable()
export class JobsService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: JobsApi) {
    }

    getJobs(workspaceId: string, projectId: string, status: string): Observable<any[]> {
        return this.api.getJobs(workspaceId, projectId, status);
    }

    createJob(job: any): Observable<any> {
        return this.api.createJob(job);
    }

    getJob(id: string): Observable<any> {
        return this.api.getJob(id);
    }

    getPresignedLink(key: string): Observable<any> {
        return this.api.getPresignedLink(key);
    }

    refereshJobStatus(id: string): Observable<any> {
        return this.api.refereshJobStatus(id);
    }
}
