import { Component, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppStore } from '../../@core/stores/app.store';
import { TxtImportService } from '../../@core/backend/common/services/txtimport.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'file-import-dialog',
    styleUrls: ['./file-import-dialog.component.scss'],
    templateUrl: './file-import-dialog.component.html',
  })
  export class FileImportDialogComponent implements OnInit, AfterContentChecked {
    loading: boolean;
    uploadFileFormGroup: FormGroup;
    preview: any;

    displayedColumns: string[];
    dataSource = [];
    spec = {};
    outputSpec = { spec: {}, filename: ''};
    specError = '';

    targetProperties: any[];

    constructor(
      public dialogRef: MatDialogRef<FileImportDialogComponent>,
      public appStore: AppStore,
      private formBuilder: FormBuilder,
      public txtImportService: TxtImportService,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.targetProperties = data.targetProperties;
    }

    ngOnInit() {
      this.uploadFileFormGroup = this.formBuilder.group({
        file: ['', Validators.required],
    });
    }

    ngAfterContentChecked() {
    }

    onNoClick(): void {
      this.dialogRef.close({});
    }

    propSelectionChange(header: string, index: number, prop: any) {
      this.spec[`${header}-${index}`] = { prop: prop.value, index };
      this.validateSpec();
    }

    validateSpec() {
      this.outputSpec.spec = {};
      let empty = true;
      const props = [];
      let dups = false;
      if (Object.keys(this.spec).length) {
        Object.keys(this.spec).forEach(key => {
          if (this.spec[key] && this.spec[key].prop !== 'undefined' && typeof this.spec[key].prop !== 'undefined') {
            empty = false;
            this.outputSpec.spec[this.spec[key].prop] = this.spec[key].index;
            if (props.indexOf(this.spec[key].prop) >= 0) {
              dups = true;
            } else {
              props.push(this.spec[key].prop);
            }
          }
        });
        if (empty) {
          this.specError = 'No columns selected';
          this.outputSpec.spec = {};
        }
        if (dups) {
          this.specError = 'Duplicate column selection';
          this.outputSpec.spec = {};
        }
        if (!empty && !dups) {
          this.specError = '';
        }
      } else {
        this.outputSpec.spec = {};
      }
    }

    previewFile(cstr) {
      const file: File = cstr.file._files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      this.loading = true;
      this.txtImportService.preview(formData).subscribe(
        result => {
          this.preview = result;
          this.dataSource = result['rows'];
          this.displayedColumns = result['headers'];
          this.outputSpec.spec = {};
          this.outputSpec.filename = result['fileName'];
          this.spec = {};
          this.displayedColumns.forEach((col, index) => {
            this.spec[`${col}-${index}`] = {};
          });
          // this.spec = this.displayedColumns.reduce(function(obj, v) {
          //   obj[v] = {};
          //   return obj;
          // }, {});
          this.loading = false;
          this.validateSpec();
      }, error => {
        console.error(error);
        this.loading = false;
      });
    }
  }
