import { Observable } from 'rxjs';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { Settings } from './settings';

export interface Project {
    name: string;
    key: string;
    description: string;
    type: string;
    id: string;
    // module: string;
    projectId?: any;
    userOwner?: string;
    teamOwner?: string;
    active?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

export interface Subset {
    id?: string;
    projectId: string;
    sourceTerminologyResources: Array<{ type: string, reference: string, name: string }>;
    createdAt?: string;
    updatedAt?: string;
}

export interface SubsetRecord {
    projectId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    code: string;
    display: string;
    active: boolean;
    author?: string;
    history?: [any];
}

export interface SimpleMap {
    id?: string;
    projectId: string;
    key: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    targetTerminologyResources: Array<{ type: string, reference: string, name: string }>;
    workflowDefinitionId: string;
    rf2ExportConfig?: Rf2ExportConfig;
    refsetConceptData?: {
        fsn: {
            term: string,
            descriptionId: string,
        },
        preferred: {
            term: string,
            descriptionId: string,
        },
        synonyms: [
            {
                term: string,
                descriptionId: string,
            },
        ],
        concept: {
            term: string,
            conceptId: string,
        },
        parent: {
            term: string,
            conceptId: string,
        },
        export: boolean,
    };
    createdAt?: string;
    updatedAt?: string;
}
export interface SimpleMapRecord {
    id?: string;
    projectId: string;
    taskListId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    code: string;
    display: string;
    mapTargetCode?: string;
    mapTargetDisplay?: string;
    mapTargetTerminologyResource?: { type: string, reference: string, name: string };
    active: boolean;
    wfState: string;
    mapEquivalence?: string;
    workList?: string;
    assignee?: string;
    author?: string;
    history?: [any];
}

export interface SimpleMapTasklist {
    id?: string;
    projectId: string;
    projectKey: string;
    key: number;
    assignee?: string;
    summary: string;
    description: string;
    state: string;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    savedConcepts: {
        code: string,
        display: string,
        definitionStatus: string,
    }[];
}

export interface AdvancedMap {
    id?: string;
    projectId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    targetTerminologyResources: Array<{ type: string, reference: string, name: string }>;
    workflowDefinitionId: string;
    mapCardinality: string;
    allowsEClasTarget: boolean;
    hasMapRule: boolean;
    hasMapAdvice: boolean;
    hasMapCategory: boolean;
    hasCorrelation: boolean;
    mapCategories?: Array<any>;
    correlationValues?: Array<any>;
    properties?: Array<any>;
    rf2ExportConfig?: Rf2ExportConfig;
    refsetConceptData?: {
        fsn: {
            term: string,
            descriptionId: string,
        },
        preferred: {
            term: string,
            descriptionId: string,
        },
        synonyms: [
            {
                term: string,
                descriptionId: string,
            },
        ],
        concept: {
            term: string,
            conceptId: string,
        },
        parent: {
            term: string,
            conceptId: string,
        },
        export: boolean,
    };
}

export interface AdvancedMapRecord {
    id?: string;
    projectId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    code: string;
    display: string;
    mapGroups: {
        mapTargetTerminologyResource: { type: string, reference: string, name: string },
        mapRows: {
            mapPriority: number,
            mapTargetCode: string,
            mapTargetDisplay: string,
            mapTargetECL: string,
            mapRule: string,
            mapAdvice: string,
            mapCategory: string,
            correlation: string,
            properties: { attribute: string, value: string }[],
        }[];
    }[];
    active: boolean;
    wfState: string;
    workList: string;
    assignee: string;
    author: string;
    history: [any];
}

export interface Refset {
    // workflowDefinitionId: string;
    id?: string;
    projectId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    refsetPattern: string;
    properties: {
            attributeDescription: {
                code: string,
                display: string,
            },
            attributeType: {
                code: string,
                display: string,
            },
            attributeOrder: number;
            headerName: string,
            optional: boolean;
            rangeEcl: string;
        }[];
    referenceComponentShouldBeUnique: boolean;
    moduleId: string;
    fhirURI: string;
    state: string;
    linkedQuery: string;
    rf2ExportConfig?: Rf2ExportConfig;
    refsetConceptData: {
        fsn: {
            term: string,
            descriptionId: string,
        },
        preferred: {
            term: string,
            descriptionId: string,
        },
        synonyms:
            {
                term: string,
                descriptionId: string,
            }[],
        concept: {
            term: string,
            conceptId: string,
        },
        parent: {
            term: string,
            conceptId: string,
        },
        export: boolean,
    };
    subtype?: string;
    branchBase?: string;
    branchHead?: string;
    containsContent?: boolean;
    lastPromotion?: string;
    createdAt?: string;
    updatedAt?: string;
}


export interface RefsetRecord {
    workspaceId?: string;
    projectId?: string;
    id?: string;
    // sourceTerminologyResource: { type: string, reference: string, name: string };
    code: string;
    properties?: [
        {
            attributeDescription: {
                code: string,
                display: string,
            },
            attributeValue?: {
                codeValue?: {
                    code?: string,
                    display?: string,
                },
                stringValue?: string,
                numValue?: number,
            },
            attributeOrder: number;
        }
    ];
    display: string;
    active: boolean;
    wfState?: string;
    workList?: string;
    assignee?: string;
    author?: string;
    history?: [any];
}


export interface ConceptExtension {
    // workflowDefinitionId: string;
    id?: string;
    projectId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    workflowDefinitionId: string;
    snomedExtension: string;
    rf2ExportConfig?: Rf2ExportConfig;
}

export interface SnomedExtension {
    // workflowDefinitionId: string;
    id?: string;
    projectId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    rf2ExportConfig?: Rf2ExportConfig;
}

export interface AuthoringProject {
    id?: string;
    projectId: string;
    key: string;
    subtype: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    lead: string;
    snomedExtension: string;
    rf2ExportConfig: {};
    fhirExportConfig: {};
    assertionGroupNames: string;
    savedConcepts: Array<{
        code: string,
        display: string,
        definitionStatus: string,
    }>;
    langRefsets: {
        conceptId: String,
        term: String,
    }[];
    state: string;
}

export interface Rf2ExportConfig {
    releaseDate: string;
    moduleConceptData: {
        export: boolean,
        fsn: {
            term: string,
            descriptionId: string,
        },
        preferred: {
            term: string,
            descriptionId: string,
        },
        synonyms:
        {
            term: string,
            descriptionId: string,
        }[],
        conceptId: string,
        dependencyModuleId: string,
        dependencyModuleEffectiveTime: string,
    };
    languageConceptData: {
        export: boolean,
        fsn: {
            term: string,
            descriptionId: string,
        },
        preferred: {
            term: string,
            descriptionId: string,
        },
        synonyms:
        {
            term: string,
            descriptionId: string,
        }[],
        conceptId: string,
    };
    languageCode: string;
    termSpaceTrForIdGeneration: string;
    contributingWorkspace?: boolean;
}

export interface ConceptExtensionRecord {
    id?: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    projectId: string;
    code: string;
    axioms: Array<{
        definitionId: string,
        definitionStatusId: string,
        relationships: Array<{
            relTarget: {
                term: string,
                conceptId: string,
            },
            relType: {
                term: string,
                conceptId: string,
            },
            groupId: number,
        }>;
    }>; display: string;
    // descriptions: [{}]; // CHANGE THIS BEFORE PUSH
    descriptions: {
        descriptionId: string,
        term: string,
        typeId: string,
        acceptabilityId: string,
    }[];
    active: boolean;
    workList?: string;
    wfState?: string;
    assignee?: string;
    author?: string;
    history?: any[];
}

export interface AuthoringTask {
    id?: string;
    projectId: string;
    projectKey: string;
    key?: number;
    summary: string;
    description: string;
    assignee: string;
    reviewers: string[];
    state: string;
    savedConcepts: Array<{
        code: string,
        display: string,
        definitionStatus: string,
    }>;
    updatedAt?: string;
    createdAt?: string;
}

export interface AuthoringRecord {
    id?: string;
    projectId: string;
    taskId: string;
    sourceTerminologyResource: { type: string, reference: string, name: string };
    code: string;
    axioms: Array<{
        definitionId: string,
        definitionStatus: string,
        active: boolean,
        relationships: Array<{
            relTarget: {
                term: string,
                conceptId: string,
                definitionStatus: string,
            },
            relType: {
                term: string,
                conceptId: string,
            },
            groupId: number,
            active: boolean,
        }>;
    }>;
    relationships: Array<{
        relTarget: {
            term: string,
            conceptId: string,
            definitionStatus: string,
        },
        relType: {
            term: string,
            conceptId: string,
        },
        groupId: number,
        active: boolean,
    }>;
    display: string;
    descriptions: Array<{
        descriptionId: string,
        term: string,
        typeId: string,
        active: boolean,
        caseSensitivityId: string,
        acceptabilityMap:
            {
                languageRefsetId: string,
                acceptabilityId: string,
                active: boolean,
            }[],
    }>;
    definitionStatus: string;
    active: boolean;
    assignee: string;
    author: string;
    history: any[];
    timestamp?: number;
}
export interface ReviewMessage {
    taskId: string;
    message: string;
    subjectConceptIds: string[];
    feedbackRequested: boolean;
    author: string;
    unread: boolean;
    createdAt?: string;
    updatedAt?: string;
}


export abstract class ProjectData {
    // abstract get gridDataSource(): DataSource;
    // abstract getCurrentUser(): Observable<User>;
    abstract list(): Observable<Project[]>;
    // abstract get(id: string): Observable<User>;
    // abstract update(user: User): Observable<User>;
    // abstract updateCurrent(user: User): Observable<User>;
    abstract create(project: Project): Observable<Project>;
    // abstract delete(id: string): Observable<boolean>;
}
