import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BriefcaseApi } from '../api/briefcase.api';
import { BriefcaseRecord } from '../../../interfaces/common/briefcase';

@Injectable()
export class BriefcaseService {

    public briefcaseUpdated = new BehaviorSubject<boolean>(false);
    public briefcaseUpdatedEvent = this.briefcaseUpdated.asObservable();


    constructor(
        private api: BriefcaseApi,
        ) {
    }

    update() {
        this.briefcaseUpdated.next(true);
        this.briefcaseUpdated.next(false);
    }

    findRecords(userId: string, workspaceId: string, folder: string): Observable<BriefcaseRecord[]> {
        return this.api.findRecords(userId, workspaceId, folder);
    }

    getFolders(userId: string, workspaceId: string): Observable<any[]> {
        return this.api.getFolders(userId, workspaceId);
    }

    addRecord(record: BriefcaseRecord) {
        const result = this.api.addRecord(record);
        return result;
    }

    addRecords(records: BriefcaseRecord[]) {
        const result = this.api.addRecords(records);
        return result;
    }

    updateRecord(record: BriefcaseRecord) {
        const result = this.api.updateRecord(record);
        return result;
    }

    deleteRecord(record: BriefcaseRecord): Observable<boolean> {
        const result = this.api.deleteRecord(record);
        return result;
    }

    moveRecords(userId: string, workspaceId: string, folder: string,
        toUserId: string, toWorkspaceId: string, toFolder: string) {
        const result = this.api.moveRecords(userId, workspaceId, folder, toUserId, toWorkspaceId, toFolder);
        return result;
    }

    copyRecords(userId: string, workspaceId: string, folder: string,
        toUserId: string, toWorkspaceId: string, toFolder: string) {
        const result = this.api.copyRecords(userId, workspaceId, folder, toUserId, toWorkspaceId, toFolder);
        return result;
    }

    deleteFolder(userId: string, workspaceId: string, folder: string) {
        const result = this.api.deleteFolder(userId, workspaceId, folder);
        return result;
    }

    insertFromSnowstorm(userId: string, workspaceId: string, folder: string,
        trId: string, term: string, ecl: string) {
        const result = this.api.insertFromSnowstorm(userId, workspaceId, folder, trId, term, ecl);
        return result;
    }
}
