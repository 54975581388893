import { Observable } from 'rxjs';
import { Profile } from './profiles';
// import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
// import { Settings } from './settings';

export interface SnowstormTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    moduleId: string;
    snowstormUrl: string;
    snowstormPath: string;
    idGenerationServiceUrl: string;
    templatesServiceUrl: string;
    lang: string;
    langRefsetId: string;
}

export interface CodesystemTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    description: string;
    properties: string[];
}

export interface TermspaceTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    termSpaceUrl: string;
    tsProjectId: string;
    userName: string;
    password: string;
}

export interface IcdApiTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    clientId: string;
    clientSecret: string;
}

export interface FhirTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    baseUrl: string;
    userName: string;
    password: string;
}

export interface VsacTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    vsacUsername: string;
    vsacPassword: string;
    vsacOid: string;
  }

  export interface UmlsTerminologyResource {
    type?: string;
    id?: string;
    _id?: string;
    name: string;
    userOwner: string;
    teamOwner: string;
    workspaceId: string;
    apiKey: string;
    sab: string;
  }

export abstract class TerminologyResourceData {
    // tslint:disable-next-line: max-line-length
    abstract createSnowstormTerminologyResource(str: SnowstormTerminologyResource): Observable<SnowstormTerminologyResource>;
    // tslint:disable-next-line: max-line-length
    abstract updateSnowstormTerminologyResource(str: SnowstormTerminologyResource): Observable<SnowstormTerminologyResource>;
    // tslint:disable-next-line: max-line-length
    abstract createCodesystemTerminologyResource(str: CodesystemTerminologyResource): Observable<CodesystemTerminologyResource>;
    // tslint:disable-next-line: max-line-length
    abstract updateCodesystemTerminologyResource(str: CodesystemTerminologyResource): Observable<CodesystemTerminologyResource>;
    // tslint:disable-next-line: max-line-length
    abstract createTermspaceTerminologyResource(tstr: TermspaceTerminologyResource): Observable<TermspaceTerminologyResource>;
    // tslint:disable-next-line: max-line-length
    abstract updateTermspaceTerminologyResource(tstr: TermspaceTerminologyResource): Observable<TermspaceTerminologyResource>;
    abstract getSnowstormTerminologyResources(profile: Profile): Observable<SnowstormTerminologyResource[]>;
    abstract getCodesystemTerminologyResources(profile: Profile): Observable<CodesystemTerminologyResource[]>;
    abstract getTermspaceTerminologyResources(profile: Profile): Observable<TermspaceTerminologyResource[]>;
    abstract uploadCodes(id: string, file: any): Observable<any>;
    abstract getCodeSystemInfo(id: string): Observable<any>;
    abstract addCode(code): Observable<any>;
    abstract copyCodes(sourceCodesystemId: string, targetCodesystemId: string): Observable<any>;
    abstract getCode(id: String): Observable<any>;
    abstract updateCode(code): Observable<any>;
    abstract getTermSpaceInfo(id: string): Observable<any>;
    abstract getSnowstormInfo(id: string): Observable<any>;
    abstract getIcdInfo(id: string): Observable<any>;
    abstract deleteSnowstormTerminologyResource(str: SnowstormTerminologyResource): Observable<boolean>;
    abstract deleteTermspaceTerminologyResource(tstr: TermspaceTerminologyResource): Observable<boolean>;
    abstract deleteCodesystemTerminologyResource(cstr: CodesystemTerminologyResource): Observable<boolean>;
    abstract deleteICDApiTerminologyResource(icdtr: IcdApiTerminologyResource): Observable<boolean>;
    abstract deleteFhirTerminologyResource(fhirtr: FhirTerminologyResource): Observable<boolean>;
}
