import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { BriefcaseRecord } from '../../../interfaces/common/briefcase';

@Injectable()
export class BriefcaseApi {
    private readonly apiController: string = 'briefcaserecord';
    constructor(private api: HttpService) { }

    findRecords(userId: string, workspaceId: string, folder: string): Observable<BriefcaseRecord[]> {
        let url = `${this.apiController}?`;
        if (userId) { url +=  `userId=${userId}&`; }
        if (workspaceId) { url +=  `workspaceId=${workspaceId}&`; }
        if (folder) { url +=  `folder=${folder}`; }
        return this.api.get(url);
    }

    getFolders(userId: string, workspaceId: string): Observable<any[]> {
        let url = `${this.apiController}/folders?`;
        if (userId) { url +=  `userId=${userId}&`; }
        if (workspaceId) { url +=  `workspaceId=${workspaceId}&`; }
        return this.api.get(url);
    }

    addRecord(record: BriefcaseRecord) {
        return this.api.post(this.apiController, record);
    }

    addRecords(record: BriefcaseRecord[]) {
        return this.api.post(this.apiController + '/bulk', record);
    }

    updateRecord(record: BriefcaseRecord) {
        return this.api.put(this.apiController + '/' + record.id, record);
    }

    deleteRecord(record: BriefcaseRecord): Observable<boolean> {
        return this.api.delete(this.apiController + '/' + record.id);
    }

    moveRecords(userId: string, workspaceId: string, folder: string,
                toUserId: string, toWorkspaceId: string, toFolder: string) {
        let url = `${this.apiController}/move?`;
        if (userId) { url +=  `userId=${userId}&`; }
        if (workspaceId) { url +=  `workspaceId=${workspaceId}&`; }
        if (folder) { url +=  `folder=${folder}&`; }
        if (toUserId) { url +=  `toUserId=${toUserId}&`; }
        if (toWorkspaceId) { url +=  `toWorkspaceId=${toWorkspaceId}&`; }
        if (toFolder) { url +=  `toFolder=${toFolder}`; }
        return this.api.post(url, {});
    }

    copyRecords(userId: string, workspaceId: string, folder: string,
                toUserId: string, toWorkspaceId: string, toFolder: string) {
        let url = `${this.apiController}/copy?`;
        if (userId) { url +=  `userId=${userId}&`; }
        if (workspaceId) { url +=  `workspaceId=${workspaceId}&`; }
        if (folder) { url +=  `folder=${folder}&`; }
        if (toUserId) { url +=  `toUserId=${toUserId}&`; }
        if (toWorkspaceId) { url +=  `toWorkspaceId=${toWorkspaceId}&`; }
        if (toFolder) { url +=  `toFolder=${toFolder}`; }
        return this.api.post(url, {});
    }

    deleteFolder(userId: string, workspaceId: string, folder: string) {
        let url = `${this.apiController}/folder/${folder}?`;
        if (userId) { url +=  `userId=${userId}&`; }
        if (workspaceId) { url +=  `workspaceId=${workspaceId}&`; }
        // if (folder) { url +=  `folder=${folder}`; }
        return this.api.delete(url, {});
    }

    insertFromSnowstorm(userId: string, workspaceId: string, folder: string,
        trId: string, term: string, ecl: string) {
        let url = `${this.apiController}/insertFromSnowstorm?`;
        if (userId) { url +=  `userId=${userId}&`; }
        if (workspaceId) { url +=  `workspaceId=${workspaceId}&`; }
        if (folder) { url +=  `folder=${folder}&`; }
        if (trId) { url +=  `trId=${trId}&`; }
        if (term) { url +=  `term=${term}&`; }
        if (ecl) { url +=  `ecl=${ecl}`; }
        return this.api.post(url, {});
    }

}
