import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class FhirApi {
    private readonly apiController: string = 'fhircsapi';
    constructor(private api: HttpService) { }

    getValueset(fhirTr: any): Observable<any[]> {
        const url = `${this.apiController}/${fhirTr.id}/valueset?valueSetUrl=${fhirTr.valueset}`;
        return this.api.get(url);
    }

    expandValueset(fhirTr: any, filter: string, offset: number, count: number): Observable<any[]> {
        let url = `${this.apiController}/${fhirTr.id}/valueset/expand?valueSetUrl=${fhirTr.valueset}`;
        if (filter) { url += `&filter=${filter}`; }
        if (offset) { url += `&offset=${offset}`; }
        if (count) { url += `&count=${count}`; }
        return this.api.get(url);
    }

}
