import { Injectable } from '@angular/core';
// import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class IcdApi {
    private readonly apiController: string = 'icdapi';
    constructor(private api: HttpService) { }

    getVersions(icdTrId: string): Observable<any[]> {
        const url = `${this.apiController}/${icdTrId}/release/10?lang=en`;
        return this.api.get(url);
    }

    getRoot(icdTrId: string, version: string): Observable<any[]> {
        const url = `${this.apiController}/${icdTrId}/release/10/${version}?lang=en`;
        return this.api.get(url);
    }

    @Cacheable()
    getCode(icdTrId: string, version: string, code: string): Observable<any[]> {
        if (!code) code = '';
        const url = `${this.apiController}/${icdTrId}/release/10/${version}/${code}?lang=en`;
        return this.api.get(url);
    }
}
