import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TermspaceApi } from '../api/termspace.api';

@Injectable()
export class TermspaceService {

    public resourcesUpdated = new BehaviorSubject<boolean>(false);
    public resourcesUpdatedEvent = this.resourcesUpdated.asObservable();

    constructor(private api: TermspaceApi) {
    }

    getProject(trId: string): Observable<any> {
        return this.api.getProject(trId);
    }

    getProjects(trId: string): Observable<any[]> {
        return this.api.getProjects(trId);
    }

    searchConceptsEcl(trId: string, ecl: string, semtag: string, term: string, moduleId: string, skip: number,
        limit: number): Observable<any> {
        return this.api.searchConceptsEcl(trId, ecl, semtag, term, moduleId, skip, limit);
    }
    searchConcepts(trId: string, query: string, skipTo: number,
        returnLimit: number, lang: string): Observable<any[]> {
        return this.api.searchConcepts(trId, query, skipTo, returnLimit, lang);
    }

    getConcept(trId: string, conceptId: string): Observable<any> {
        return this.api.getConcept(trId, conceptId);
    }

    getChildren(trId: string, conceptId: string): Observable<any[]> {
        return this.api.getChildren(trId, conceptId);
    }

    getAncestors(trId: string, conceptId: string): Observable<any[]> {
        return this.api.getAncestors(trId, conceptId);
    }

    getParents(trId: string, conceptId: string): Observable<any[]> {
        return this.api.getParents(trId, conceptId);
    }

    deleteTokens(): void {
        this.api.deleteTokens();
    }

    checkPermission(trId: string, username: string, permission: string) {
        return this.api.checkPermission(trId, username, permission);
    }

    generateId(trId: string, partitionId: string, namespace: string, limit: number) {
        return this.api.generateId(trId, partitionId, namespace, limit);
    }

    // tslint:disable-next-line: max-line-length
    getDomainAttributes(trId: string, parentIds: string[], proximalPrimitiveModeling: boolean, contentType: string): Observable<any> {
        return this.api.getDomainAttributes(trId, parentIds, proximalPrimitiveModeling, contentType);
    }

    getAttributeValues(trId: string, contentType: string, attributeId: string, searchTerm: string) {
        return this.api.getAttributeValues(trId, attributeId, searchTerm, contentType);
    }

}
