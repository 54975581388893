import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ngx-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
    @Input() data: string;
    @Output() test = new EventEmitter();
    constructor() { }

    ngOnInit() {
    }

    log = () => this.test.emit(this.data);
}
